@import '../variables';

.react-aria-Checkbox {
  @extend %text-color-gray700;
  @extend %text-size-sm;
  @extend %text-weight-regular;

  --selected-color: $gray-500;
  --selected-color-pressed: $gray-500;
  --checkmark-color: $white;

  display: flex;
  flex-direction: row;
  align-items: center;
  color: $gray-800;
  cursor: pointer;

  forced-color-adjust: none;
  gap: calculateRem(12px);

  .checkbox {
    display: flex;
    width: 1.25rem;
    height: 1.25rem;
    align-items: center;
    justify-content: center;
    border: 2px solid $gray-500;
    background-color: $gray-200;
    transition: all 200ms;
  }

  svg {
    width: 1rem;
    height: 1rem;
    fill: none;
    stroke: $white;
    stroke-dasharray: calculateRem(22px);
    stroke-dashoffset: 66;
    stroke-width: calculateRem(3px);
    transition: all 200ms;
  }

  &[data-pressed] .checkbox {
    border-color: $gray-700;
    background-color: $gray-400;
  }

  &[data-focus-visible] .checkbox {
    border: 2px solid $gray-700;
  }

  &[data-selected] {
    .checkbox {
      border-color: $black;
      background: $black;
    }

    &[data-pressed] .checkbox {
      border-color: $black;
      background: $black;
    }

    svg {
      stroke-dashoffset: 44;
    }
  }

  &[data-invalid] {
    color: $red;

    .checkbox {
      --checkmark-color: $white;
      border-color: $red;
    }

    &[data-pressed] .checkbox {
      border-color: $red;
    }

    &[data-selected],
    &[data-indeterminate] {
      .checkbox {
        background: $red;
      }

      &[data-pressed] .checkbox {
        background: $red;
      }
    }
  }

  &[data-disabled] {
    color: $gray-500;

    .checkbox {
      border-color: $gray-400;
      background: $gray-200;
    }

    & + [slot='description'] {
      color: $gray-500;
    }
  }

  > [slot='description'] {
    flex-basis: 100%;
    margin-top: map-get($sizes, 'xxs');
  }

  .checkbox + [slot='description'] {
    @extend %text-size-xxs;
    @extend %lh-md;
    @extend %text-color-gray700;
  }
}

.react-aria-CheckboxField {
  [slot='errorMessage'] {
    @extend %text-size-xxs;
    color: map-get($colors, 'error');
    flex-basis: 100%;
  }

  > label {
    flex-basis: 100%;
  }
}

/* css for slate link */
.slate-link-checkbox {
  .ui.checkbox {
    margin-left: 8px;

    .label {
      font-size: 15px;
      font-weight: 300;
    }
  }
}
