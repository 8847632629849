@import '../variables';

.react-aria-DateField {
  color: var(--text-color);
}

.react-aria-DateInput {
  display: flex;
  width: fit-content;
  width: 100%;
  min-width: 276px;
  min-height: 3.75rem;
  align-items: center;
  padding: 4px;
  border: 2px solid $gray-400;
  border-radius: 0;
  background-color: $gray-100;
  forced-color-adjust: none;
  white-space: nowrap;

  &[data-focus-within] {
    outline: 2px solid $black;
    outline-offset: -2px;
  }
}

.react-aria-DateSegment {
  padding: 0 2px;
  font-variant-numeric: tabular-nums;
  text-align: end;

  &[data-type='literal'] {
    padding: 0;
  }

  &[data-placeholder] {
    color: $black;
    font-style: italic;
  }

  &:focus {
    border-radius: 4px;
    caret-color: transparent;
    outline: none;
  }

  &[data-invalid] {
    color: $red;

    &:focus {
      color: $red;
    }
  }
}

.react-aria-DateField {
  .react-aria-FieldError {
    color: $red;
    font-size: 12px;
  }

  [slot='description'] {
    font-size: 12px;
  }
}
