.ratio {
  position: relative;
  width: 100%;
  border: 0;
}

.ratio-16x9 {
  aspect-ratio: 16/9;
}

.ratio-4x3 {
  aspect-ratio: 4/3;
}

.ratio-21x9 {
  aspect-ratio: 21/9;
}

.ratio-1x1 {
  aspect-ratio: 1/1;
}

.block.video {
  .video-inner.short {
    /* Important overrides the long selector coming from core. */
    max-width: 360px !important;
    aspect-ratio: 9 / 16;

    .ui.embed {
      height: 100%;
      padding-bottom: unset;

      img.placeholder {
        position: unset;
        width: 100%;
        height: 100%;
        transform: unset;
      }
    }
  }
}
