fieldset {
  display: flex;
  flex-direction: column;
  padding: 0;
  border: 0;
  margin: 0;
  gap: map-get($sizes, 'lg');
}

.react-aria-Text {
  @extend %text-weight-regular;
  padding-left: map-get($sizes, 'xxs');
  font-style: italic;
}
