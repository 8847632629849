#page-edit {
  .q {
    &.container {
      z-index: 0;
      padding: map-get($sizes, 'lg');
      container-type: unset;
    }
  }
}

.ui.container.tags {
  display: none; /* hide tags, but keep them in the source */
}
