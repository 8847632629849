/**
 * Default variables
 * Remarks: If you add, update or remove a variable, consider to update SCSS.test.tsx in same directory
*/

// FONT STYLES
$defaultFontSize: 16px;
$lineHeight: 1rem;
$fontInter: 'Inter', sans-serif;
$fontSpectral: 'Spectral', serif;

// NAVIGATION
$navDesktopWidth: 340px;

// FONT SIZES
$font-size-12: 12px;
$font-size-13: 13px;
$font-size-14: 14px;
$font-size-15: 15px;
$font-size-16: 16px;
$font-size-18: 18px;
$font-size-20: 20px;
$font-size-22: 22px;
$font-size-24: 24px;
$font-size-32: 32px;
$font-size-40: 40px;

// FONT WEIGHTS
$font-weight-300: 300;
$font-weight-400: 400;
$font-weight-500: 500;
$font-weight-600: 600;
$font-weight-700: 700;
$font-weight-800: 800;

// LINE HEIGHTS
$line-height-0: 1;
$line-height-1: 1.1;
$line-height-2: 1.2;
$line-height-3: 1.3;
$line-height-4: 1.4;
$line-height-5: 1.5;
$line-height-6: 1.6;
$line-height-7: 1.625;

// COLORS
$yellow: #fde70e;
$yellow-1: #ead20a; // nav active color
$yellow-2: #f5dd19; // nav hover color
$yellow-3: #fff48d;
$lemon: #fef387;
$black: #000;
$white: #fff;
$red: #df305b;
$orange: #fbd100;
$green: #3eb058;
$purple: #cc55a5;
$lightpurple: #fdeff8;
$blue-listbox: #b3d7ff;
$lightblue: #86bfff;

// GRAY SCALES
$gray-100: #f7f7f5;
$gray-200: #f1f1ee;
$gray-300: #dee2e6;
$gray-400: #deded9;
$gray-500: #bebdb9;
$gray-600: #999999;
$gray-700: #767573;
$gray-800: #4c4c4c;
$gray-900: #212529;

// WIDTHS
$widths: (
  '25': 25,
  '33': 33.3333,
  '50': 50,
  '75': 75,
  '100': 100,
);

// BREAKPOINTS
$smallest-mobile-screen: 320px;
$largest-mobile-screen: 767px;
$largest-tablet-screen: 1023px;
$smallest-desktop-screen: 1024px;
$large-desktop-screen: 1280px;
$small-laptop-screen: 1680px;

$breakpoints: (
  'xxs': $smallest-mobile-screen,
  'xs': $largest-mobile-screen,
  'sm': $largest-tablet-screen,
  'md': $smallest-desktop-screen,
  'lg': $large-desktop-screen,
  'xl': $small-laptop-screen,
);

// NAMED COLORS
$colors: (
  'primary': $black,
  'secondary': $yellow,
  'white': $white,
  'lemon': $lemon,
  'success': $green,
  'error': $red,
  'warning': $orange,
  'dev': $purple,
  'test': $lightblue,
  'gray100': $gray-100,
  'gray200': $gray-200,
  'gray300': $gray-300,
  'gray400': $gray-400,
  'gray500': $gray-500,
  'gray600': $gray-600,
  'gray700': $gray-700,
  'gray800': $gray-800,
  'gray900': $gray-900,
);

// FONT-STYLES
$font-styles: (
  'xxs': (
    'font-size': $font-size-12,
    'line-height': $line-height-0,
  ),
  'xss': (
    'font-size': $font-size-13,
    'line-height': $line-height-5,
  ),
  'xs': (
    'font-size': $font-size-14,
    'line-height': $line-height-0,
  ),
  'xsm': (
    'font-size': $font-size-15,
    'line-height': $line-height-0,
  ),
  'sm': (
    'font-size': $font-size-16,
    'line-height': $line-height-0,
  ),
  'md': (
    'font-size': $font-size-18,
    'line-height': $line-height-0,
  ),
  'smd': (
    'font-size': $font-size-20,
    'line-height': $line-height-2,
  ),
  'lg': (
    'font-size': $font-size-22,
    'line-height': $line-height-2,
  ),
  'xl': (
    'font-size': $font-size-32,
    'line-height': $line-height-0,
  ),
  'xxl': (
    'font-size': $font-size-40,
    'line-height': $line-height-3,
  ),
);

// FONT-WEIGHTS
$font-weights: (
  'light': $font-weight-300,
  'regular': $font-weight-400,
  'medium': $font-weight-500,
  'semibold': $font-weight-600,
  'bold': $font-weight-700,
  'extrabold': $font-weight-800,
);

// LINE-HEIGHTS
$line-heights: (
  'xxs': $line-height-0,
  'xs': $line-height-1,
  'sm': $line-height-2,
  'md': $line-height-3,
  'smd': $line-height-4,
  'lg': $line-height-5,
  'xl': $line-height-6,
  'xxl': $line-height-7,
);

// DEFAULT SIZES
$sizes: (
  'none': 0,
  'xxs': 5px,
  'xs': 10px,
  'sm': 15px,
  'md': 20px,
  'lg': 30px,
  'xl': 50px,
  'xxl': 100px,
);

$icon-sizes: (
  'XS': 12px,
  'S': 18px,
  'M': 29px,
  'L': 36px,
  'XL': 48px,
  'XXL': 72px,
);

// ANIMATION SETTINGS
$animation-delay-time: 10ms;
$animation-scale: 1.2;

$animation-time: (
  'slow': 1000ms,
  'medium': 500ms,
  'fast': 150ms,
);

$ease: (
  'in': ease-in,
  'out': ease-out,
  'in-out': ease-in-out,
  'linear': linear,
  'ease': ease,
  'ease-in': ease-in,
  'ease-out': ease-out,
  'ease-in-out': ease-in-out,
);

$speed: (
  'slow': 1000ms,
  'medium': 500ms,
  'fast': 250ms,
);

// Full bleed teaser
$fullBleedTeaserHeight: 526px;
$fullBleedTeaserTextBoxWidth: 460px;

// VOLTO LIGHT THEME VARIABLES
:root {
  --layout-container-width: 1280px;
  --default-container-width: unset;
  --narrow-container-width: unset;
}

$text-color: $black !default;
$blue-for-grey-contrast: $gray-700 !default;
