%gradientPrev {
  position: absolute;
  right: 0;
  top: 0;
  width: 35px;
  height: 35px;
  z-index: 0;
  content: '';
  background: linear-gradient(
    90deg,
    rgba(255, 255, 255, 0) 0,
    rgb(255, 255, 255) 90%
  );
}

%gradientNext {
  position: absolute;
  left: 0;
  top: 0;
  width: 50px;
  height: 50px;
  z-index: 1;

  content: '';
  background: linear-gradient(
    90deg,
    rgba(255, 255, 255, 1) 0%,
    rgba(255, 255, 255, 0) 100%
  );
}

.tabs-accessibility {
  overflow: hidden;

  &.gradient_next:before {
    @extend %gradientNext;
  }

  &.gradient_prev:after {
    @extend %gradientPrev;
  }

  @media screen and (max-width: $largest-mobile-screen) {
    &::after {
      position: absolute;
      right: 0;
      top: 0;
      width: 35px;
      height: 35px;
      z-index: 0;
      content: '';
      background: linear-gradient(
        90deg,
        rgba(255, 255, 255, 0) 0,
        rgb(255, 255, 255) 90%
      );
    }
  }
}

.tabs-area {
  .accordion-tools button:first-of-type {
    display: none;
  }
}

.tabs-secondary-variant.menu {
  border: none;
  box-shadow: none;
}
.tabs-secondary-variant.menu a {
  border: none;
}
.tabs-block
  .tabs-secondary-variant.ui.menu:not(.inverted, .pointing)
  .active.item {
  background: none;
}
.tabs-secondary-variant.ui.menu .item {
  font-size: $font-size-20;
  font-weight: $font-weight-600;
  display: inline-block;
  color: $gray-700;
}
.tabs-secondary-variant.ui.menu .item.active {
  color: black;
}
.tabs-secondary-variant.ui.menu .item:hover {
  color: black;
}

.tabs-secondary-variant.menu :before {
  display: none;
}

.tab_menu.ui.menu {
  border: none;
  box-shadow: none;
}

.tabs-accessibility > .ui.menu .item.active {
  border-width: 0px !important;
}

.tab_button {
  outline: none !important;
  border: none !important;
  white-space: nowrap;
  width: 100%;

  &.button {
    padding: 0 2rem 0 0 !important;
  }
}

.tabs-secondary-variant.menu .item .menu-item-text::after {
  position: relative;
  top: 0%;
  bottom: 0;
  left: 0;
  display: block;
  width: 0%;
  padding-top: 10px;
  border-bottom: 4px solid #fde703;
  content: '';
  transform: scaleX(0);
  transition: width 0.3s ease-in 0.3s;
}

.tabs-secondary-variant.menu .active.item .menu-item-text::after {
  position: relative;
  top: 0%;
  bottom: 0;
  left: 0;
  display: block;
  width: 100%;
  border-bottom: 4px solid #fde703;
  content: '';
  transform: scaleX(1);
  transition: width 0.3s ease-out 0.1s;
}

.tabs-accessibility .ui.segment.tab {
  padding: 1rem 0;
  width: 100%;
  overflow: visible;
}

// Ensure tab content has same width as navigation wrapper
.tabs-accessibility > .ui.segment {
  width: 100%;
  max-width: 100%;
  margin-left: 0 !important;
  margin-right: 0 !important;
  box-sizing: border-box;
}

// Match tab content width with tabs-navigation-wrapper
.tabs-container {
  display: flex;
  flex-direction: column;
  align-items: stretch;

  .ui.tab.segment {
    flex: 1;
    width: 100%;
    max-width: 100%;
  }

  // This ensures the tab content has exactly the same width as tabs-navigation-wrapper
  .ui.bottom.attached.segment.active.tab {
    width: 100%; // Match parent width
    margin-left: 0 !important;
    margin-right: 0 !important;
  }

  // Ensure the tab content container matches the navigation width
  .tabs-navigation-wrapper {
    width: 100%;

    & + .ui.segment {
      width: 100%;
    }
  }
}

// Add specific styling for tab content to match navigation width
.tabs-accessibility .tab-name {
  width: 100%;
  box-sizing: border-box;
}

// Navigation buttons styling
.tabs-container {
  position: relative;
  width: 100%;
  overflow: hidden;
}

.tabs-secondary-variant.menu {
  overflow-x: scroll;
  scrollbar-width: none;
  -ms-overflow-style: none;
  scroll-behavior: smooth;
  position: relative;
  width: 100%;

  // Hide scrollbar in webkit browsers
  &::-webkit-scrollbar {
    display: none;
  }
}

// When navigation buttons are present, adjust the menu width
.tabs-navigation-wrapper:has(.tabs-navigation-buttons) {
  .tabs-secondary-variant.menu {
    width: calc(100% - 80px); // Make room for buttons at the right
    @media screen and (max-width: $largest-mobile-screen) {
      width: 100%;
    }
  }
}

.tabs-navigation-buttons {
  display: flex;
  justify-content: flex-end;
  align-items: center;
  gap: map-get($sizes, 'xs');

  position: absolute;
  right: 0;
  top: 0;
  z-index: 10;
  background: transparent;
  padding-left: 20px;
  min-width: 80px;

  .scroll-button {
    display: flex;
    justify-content: center;
    align-items: center;
    padding: unset;
    width: map-get($sizes, 'lg');
    min-width: map-get($sizes, 'lg');
  }

  .scroll-left {
    width: map-get($sizes, 'lg');
    height: map-get($sizes, 'lg');
  }

  .scroll-right {
    width: map-get($sizes, 'lg');
    height: map-get($sizes, 'lg');
  }

  @media screen and (max-width: $largest-mobile-screen) {
    display: none;
  }

  &.gradient_prev:after {
    position: absolute;
    right: 0;
    top: 0;
    width: 120px;
    height: 35px;
    z-index: -1;
    content: '';
    background: linear-gradient(
      90deg,
      rgba(255, 255, 255, 0) 0,
      rgb(255, 255, 255) 30%
    );
  }
}

.tab-nav-button {
  margin-left: 0.5rem !important;
  background-color: $gray-200 !important;
  color: $gray-700 !important;
  font-weight: $font-weight-600 !important;
  height: 28px !important;
  display: flex !important;
  align-items: center !important;
  justify-content: center !important;
  box-shadow: 0 1px 3px rgba(0, 0, 0, 0.1) !important;
  margin-top: 0 !important;
  margin-bottom: 0 !important;
  padding: 0 0.8rem !important;
  font-size: 14px !important;

  &:hover {
    background-color: $gray-300 !important;
    color: black !important;
  }
}

#page-edit {
  .ui.tabs-secondary-variant.menu {
    flex-wrap: wrap;
  }
}
