@import '../variables';

.react-aria-DatePicker {
  display: flex;
  flex-direction: row;
  flex-wrap: wrap;
  justify-content: space-between;

  .react-aria-Group {
    display: flex;
    width: 100%;
    align-items: center;
  }

  .datepicker-button-Wrapper {
    display: flex;
    width: 3rem;
    min-width: calculateRem(60px);
    height: 3rem;
    flex-direction: row;
    align-items: center;
    justify-content: space-between;
    padding: 0;
    margin-left: -4.5rem;

    .react-aria-Button {
      box-sizing: content-box;
      border: none;
      background-color: $gray-100;
      cursor: pointer;
      forced-color-adjust: none;

      &[data-pressed] {
        background: $gray-100;
        box-shadow: none;
      }

      &:focus-visible {
        outline: 0;
        outline-offset: 0;
      }

      &:nth-child(1) {
        color: $gray-800;
        font-size: $font-size-22;

        &:hover {
          color: $black;
        }
      }

      &:nth-child(2) {
        padding-top: calculateRem(4px);
      }
    }
  }

  .react-aria-DateInput {
    padding: 4px 2.5rem 4px 8px;
    @extend %mt-xxs;
  }
}

.react-aria-Popover[data-trigger='DatePicker'] {
  max-width: unset;
}

.react-aria-DatePicker {
  &[data-invalid] {
    .react-aria-DateInput {
      border-color: $red;
    }
  }

  .react-aria-Label {
    max-width: 50%;
    line-height: unset;
  }

  .react-aria-FieldError {
    max-width: 50%;
    color: $red;
    font-size: 12px;
  }

  > [slot='description'] {
    margin-top: map-get($sizes, 'xxs');
    &:nth-last-of-type(1) {
      @extend %text-size-xxs;
      @extend %lh-md;
      @extend %text-color-gray700;
    }
  }
}
