body.contenttype-plone-site,
body.contenttype-lrf,
body.view-contentsview {
  #main .breadcrumbs {
    display: none;
  }
}

#main .breadcrumbs {
  position: relative;
  @include padding($top: 'sm', $right: 'none', $bottom: 'none', $left: 'lg');
  background-color: transparent;

  &.mobile {
    overflow-x: auto;
    -webkit-overflow-scrolling: touch;
    padding-bottom: 4px;
    scrollbar-width: none;

    &::-webkit-scrollbar {
      height: 4px;
      background: transparent;
    }

    &::-webkit-scrollbar-thumb {
      background: rgba(0, 0, 0, 0.2);
      border-radius: 2px;
    }
  }

  @media screen and (max-width: $largest-tablet-screen) {
    @include padding($top: 'sm', $right: 'md', $bottom: 'none', $left: 'sm');
  }

  @media screen and (min-width: $largest-mobile-screen) {
    @include padding($top: 'sm', $right: 'lg', $bottom: 'none', $left: 'lg');
  }

  @media screen and (min-width: $largest-tablet-screen) {
    @include padding($top: 'sm', $right: 'none', $bottom: 'none', $left: 'xl');
  }

  .breadcrumb {
    // position: relative;
    display: flex;
    align-items: center;
    justify-content: flex-start;
    list-style-type: none;
    scrollbar-width: none;

    .divider {
      transform: rotate(90deg);

      &::before {
        color: inherit;
        content: '\25B2';
        font-size: 7px;
      }
    }

    .home {
      margin-right: 0.5em;

      svg {
        margin-bottom: -4px;
      }
    }

    &.mobile {
      &.gradient_next:before {
        position: absolute;
        left: 0;
        top: 0;
        width: 50px;
        height: 50px;
        z-index: 1;

        content: '';
        background: linear-gradient(
          90deg,
          rgb(255, 255, 255) 20%,
          rgba(255, 0, 0, 0) 100%
        );
      }

      &.gradient_prev:after {
        position: absolute;
        right: -15px;
        top: 0;
        width: 50px;
        height: 50px;
        z-index: 2;
        content: '';
        background: linear-gradient(
          90deg,
          rgba(255, 255, 255, 0) 0,
          rgb(255, 255, 255) 70%
        );
      }

      white-space: nowrap;
      padding-right: 0;
      overflow-x: auto;
      scrollbar-width: none;

      .section {
        &.active {
          &:last-child {
            overflow: visible;
            text-overflow: unset;
          }
        }
      }
    }

    .section {
      position: relative;
      @extend %text-size-xsm;
      @extend %text-weight-regular;
      margin: 0 map-get($sizes, 'xs') 0 map-get($sizes, 'xs');
      padding: map-get($sizes, 'xxs');
      white-space: nowrap;

      &.plus-icon {
        display: flex;
        justify-content: normal;
        align-items: center;
        @include backgroundIcon('breadcrumbPlus');
        background-color: unset;
        border: none;
        opacity: 0.5375;
        cursor: pointer;
      }

      &.active {
        @extend %text-size-xsm;
        @extend %text-color-primary;
        @extend %text-weight-regular;

        &:last-child {
          overflow: visible;
          text-overflow: ellipsis;
        }
      }

      &[data-tooltip]:before {
        background-color: black;
        left: 45%;
        box-shadow: none;
      }

      &[data-tooltip]:after {
        pointer-events: none;
        position: absolute;

        content: attr(data-tooltip);
        font-size: 12px;
        line-height: 1.5;
        font-weight: bold;
        color: $white;
        text-transform: none;
        text-align: left;
        white-space: wrap;

        background: $black;
        padding: 0.833em 1em;
        font-style: normal;

        border-radius: 0.22222222rem;
        z-index: 1;
        min-width: min-content;
        // width: 100%;

        min-width: calculateRem(200px);
        max-width: calculateRem(300px);
        box-shadow: unset;
        border: none;
      }
    }
  }
}
