.footer {
  background-color: #f8f8f8;

  .footernavigation {
    display: flex;
    flex-wrap: wrap;
    justify-content: space-between;
    padding: 60px 35px 25px 35px;
    background-color: map-get($colors, 'primary');
    @include padding($top: 'xl', $right: 'xxs', $bottom: 'lg', $left: 'xxs');

    .footernavigation__column {
      .column-title {
        margin-bottom: 0;
        @extend %text-size-xsm;
        @extend %text-weight-bold;
      }

      &:last-child {
        .column-title {
          font-size: $font-size-14;
        }
      }

      ul {
        margin-bottom: 0;
        list-style-type: none;

        li {
          margin-bottom: calculateRem(5px);

          a {
            display: inline-block;

            padding: map-get($sizes, 'xxs') 0;
            color: $gray-500 !important;
            opacity: 1;

            text-decoration: none;
            @extend %text-weight-medium;
            @extend %lh-xxl;
            @extend %text-size-xsm;

            &:hover {
              color: $white !important;
              text-decoration: none;
            }
          }
        }
      }
    }

    .footer__logos {
      width: 30%;
      max-height: calculateRem(170px);
      flex: 0 0 30%;

      a {
        display: inline-block;
        width: 100%;
        height: calculateRem(50px);
        margin-right: 10px;
        background-repeat: no-repeat;
        opacity: 0.7;

        &:hover {
          opacity: 1;
        }

        &.swissuniversities {
          min-width: 250px;
          @include backgroundIcon('swissuniversities');
        }

        &.eua {
          min-width: 200px;
          height: 40px;
          @include backgroundIcon('eua');
        }
      }
    }

    @media screen and (max-width: $largest-mobile-screen) {
      flex-direction: column;
      padding: 30px 5px 25px 5px;

      .footernavigation__column {
        border-bottom: 1px solid rgba(255, 255, 255, 0.5);
        margin: 0 20px;

        .column-title {
          position: relative;
          display: flex;
          width: 100%;
          align-items: center;
          justify-content: space-between;
          padding: 0;
          border-bottom: 0;
          color: #fff;

          span {
            flex-grow: 1;
            // padding: 12px 0 14px 0;
            padding: map-get($sizes, 'sm') 0 map-get($sizes, 'sm') 0;
            cursor: pointer;
          }

          span:after {
            position: absolute;
            right: map-get($sizes, 'xxs');
            bottom: calculateRem(18px);
            display: inline-block;
            width: calculateRem(12px);
            height: calculateRem(12px);
            background-image: url(data:image/svg+xml;base64,PHN2ZyB3aWR0aD0iMTIiIGhlaWdodD0iMTIiIHZpZXdCb3g9IjAgMCAxMiAxMiIgeG1sbnM9Imh0dHA6Ly93d3cudzMub3JnLzIwMDAvc3ZnIj48dGl0bGU+QzE2MzY1RDUtQzlBNy00REYzLTlFQUMtNkVFMTJFNjQ4MEE4PC90aXRsZT48cGF0aCBkPSJNMTEgMUwxIDExbTEwIDBMMSAxIiBzdHJva2Utd2lkdGg9IjIiIHN0cm9rZT0iI0ZGRiIgZmlsbC1ydWxlPSJldmVub2RkIiBzdHJva2UtbGluZWNhcD0icm91bmQiIHN0cm9rZS1saW5lam9pbj0icm91bmQiLz48L3N2Zz4=);
            content: '';
            transform: rotate(45deg);
            transition: transform 0.5s ease-in-out;
          }
        }

        &.is-open .column-title span:after {
          transform: rotate(0);
        }

        &:last-child {
          border-bottom: 0;
          margin-top: calculateRem(50px);
          margin-left: calculateRem(20px);

          .column-title {
            margin-bottom: calculateRem(10px);
            color: $gray-500;
          }
        }
      }

      h2 {
        padding-top: calculateRem(12px);
        cursor: pointer;
      }

      @mixin set-max-height($item-count) {
        max-height: $item-count * (calculateRem(35px) + calculateRem(17.5px));
      }

      ul {
        overflow: hidden;
        max-height: 0;
        padding: 0;
        margin-top: 0;
        transition:
          max-height 0.6s ease-in-out,
          padding 0.3s ease-in-out;

        &.show {
          @for $i from 1 through 5 {
            &.items-#{$i} {
              @include set-max-height($i);
            }
          }
          padding: 0 0 17.5px 0;
        }
      }
    }

    @media (min-width: $largest-mobile-screen) and (max-width: $smallest-desktop-screen) {
      flex-direction: row;
      flex-wrap: wrap;
      padding: 60px 34px 25px 34px;

      .footernavigation__column {
        flex: 1 0 50%;
        padding-right: calculateRem(16px);
        padding-left: calculateRem(16px);
        margin: 15px 0;

        .column-title {
          padding-bottom: calculateRem(14px);
          border-bottom: 1px solid rgba(255, 255, 255, 0.5);
          color: map-get($colors, 'white');

          span {
            pointer-events: none;
          }
        }
      }

      .footer__logos {
        max-width: 100%;
        flex: 1 0 50%;
        padding-left: calculateRem(20px);
        margin-left: 50%;

        .column-title {
          border-bottom: 0;
          color: map-get($colors, 'gray500');
        }
      }

      ul {
        padding: 0;
      }
    }

    @media screen and (min-width: $smallest-desktop-screen) {
      display: flex;
      flex-direction: row;
      flex-wrap: wrap;
      padding: 60px 35px 25px 35px;

      .footernavigation__column {
        flex: 1 0 15%;
        padding: 0 16px;
        margin: 16px 0;

        .column-title {
          padding-bottom: calculateRem(14px);
          border-bottom: 1px solid rgba(255, 255, 255, 0.5);
          color: map-get($colors, 'white');

          span {
            pointer-events: none;
          }
        }
      }

      .footer__logos {
        min-width: calculateRem(320px);
        margin-left: 5%;

        .column-title {
          border-bottom: 0;
          color: map-get($colors, 'gray500');
        }
      }

      ul {
        padding: 0;
      }
    }

    @media (min-width: $largest-mobile-screen) and (max-width: $small-laptop-screen) {
      flex-direction: row;
      flex-wrap: wrap;

      .footernavigation__column {
        max-width: 50%;
        flex: 1 0 50%;
        padding-right: calculateRem(16px);
        padding-left: calculateRem(16px);
        margin: 16px 0;

        .column-title {
          padding-bottom: calculateRem(14px);
          border-bottom: 1px solid rgba(255, 255, 255, 0.5);
          color: map-get($colors, 'white');

          span {
            pointer-events: none;
          }
        }
      }

      .footer__logos {
        max-width: 100%;
        flex: 1 0 50%;
        padding-left: calculateRem(20px);
        margin-left: 50%;

        .column-title {
          border-bottom: 0;
          color: map-get($colors, 'gray500');
        }
      }

      ul {
        padding: 0;
      }
    }
  }
}
