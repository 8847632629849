%volto-heading-block-defaults {
  padding-top: 0;
  padding-bottom: 0;
}

.heading-wrapper,
.block .heading-wrapper {
  width: 100%;

  h2 {
    @extend %h2;
    &:last-child {
      @extend %h2;
    }
  }

  h3 {
    @extend %h3;
    @extend %volto-heading-block-defaults;
    &:last-child {
      @extend %h3;
      @extend %volto-heading-block-defaults;
    }
  }

  h4 {
    @extend %h4;
    @extend %volto-heading-block-defaults;
    &:last-child {
      @extend %h4;
      @extend %volto-heading-block-defaults;
    }
  }

  h5 {
    @extend %h5;
    @extend %volto-heading-block-defaults;
    &:last-child {
      @extend %h5;
      @extend %volto-heading-block-defaults;
    }
  }

  h6 {
    @extend %h6;
    @extend %volto-heading-block-defaults;
    &:last-child {
      @extend %h6;
      @extend %volto-heading-block-defaults;
    }
  }
}
