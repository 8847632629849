.hero-wrapper {
  position: relative;
  overflow: hidden;
  width: 100%;
  height: 50vh;
  min-height: 200px;
  max-height: 600px;

  @media screen and (max-width: $largest-tablet-screen) {
    height: unset;
    aspect-ratio: 16 / 9;
  }

  .contact-information-hero {
    position: absolute;
    top: 20%;
    padding: 0 100px 0 80px;

    @media screen and (max-width: $largest-tablet-screen) {
      h1 {
        display: none;
      }
    }
  }

  .hero {
    width: 100%;
    height: 100%;
    aspect-ratio: 3 / 1;
    object-fit: cover;
    object-position: center;

    &.bottom_right {
      object-position: right bottom;
    }

    &.bottom_left {
      object-position: left bottom;
    }

    &.top_left {
      object-position: left top;
    }

    &.top_right {
      object-position: right top;
    }

    &.center_bottom {
      object-position: center bottom;
    }

    &.center_left {
      object-position: left center;
    }
  }

  .edit-options {
    position: absolute;
    top: 0;
    right: 0;
    bottom: 0;
    left: 0;
    display: flex;

    .edit-hero {
      position: absolute;
      top: 0;
      right: 0;
      bottom: 0;
      left: 0;
      padding: 0;
      border: none;
      margin: 0;
      background-color: transparent;
      color: transparent;
      cursor: pointer;
    }

    .hero-inheritance {
      position: absolute;
      top: 8px;
      left: 8px;
      background-color: rgba(255, 255, 255, 0.7);
    }
  }
}
