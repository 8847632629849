@import '../variables';

.react-aria-Switch {
  display: flex;
  align-items: center;
  forced-color-adjust: none;
  gap: calculateRem(12px);
  @extend %text-color-gray800;
  @extend %text-size-sm;
  @extend %text-weight-regular;

  .indicator {
    width: calculateRem(32px);
    height: calculateRem(20px);
    border: 2px solid $gray-500;
    border-radius: calculateRem(16px);
    background: $gray-200;
    transition: all 200ms;

    &:before {
      display: block;
      width: calculateRem(12px);
      height: calculateRem(12px);
      border-radius: calculateRem(16px);
      margin: calculateRem(2px);
      background: $gray-500;
      content: '';
      transition: all 200ms;
    }
  }

  &[data-pressed] .indicator {
    border-color: $gray-800;
    background: $gray-400;

    &:before {
      background: $gray-600;
    }
  }

  &[data-selected] {
    .indicator {
      border-color: $black;
      background: $black;

      &:before {
        background: $white;
        transform: translateX(100%);
      }
    }

    &[data-pressed] {
      .indicator {
        border-color: $black;
        background: $black;

        &:before {
          background: $gray-400;
          transform: translateX(100%);
        }
      }
    }
  }

  &[data-focus-visible] .indicator {
    outline: 2px solid $gray-800;
    outline-offset: 2px;
  }

  &[data-disabled] {
    color: $gray-500;

    .indicator {
      border-color: $gray-400;

      &:before {
        background: $gray-400;
      }
    }

    &[data-selected] {
      .indicator {
        border-color: $gray-400;
        background-color: $gray-200;

        &:before {
          background: $gray-400;
        }
      }
    }
  }
}
