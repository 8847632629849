.block-editor-tabs_block {
  .add-block-button {
    svg.circled {
      padding: 0;
      border: 1px solid currentColor;
    }
  }
}

.block-editor-tabs_block .block-toolbar button {
  padding: 0.5rem;
  border: none;
  background: none;
  svg.circled {
    padding: 0;
    border: 1px solid currentColor;
  }
}

.block-editor-tabs_block .block-toolbar {
  position: absolute;
  z-index: 3;
  right: -9px;
  display: flex;
  justify-content: end;
  border: 1px solid rgba(120, 192, 215, 0.75);
  border-bottom-color: #fff;
  margin-top: -50px;
  background-color: #fff;
  border-top-left-radius: 1rem;
  border-top-right-radius: 1rem;
}
