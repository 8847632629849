@import '../variables';

.content-area p > a,
.content-area li > a,
.content-area .breadcrumb a,
.breadcrumbs p > a,
.breadcrumbs li > a,
.breadcrumbs .breadcrumb a {
  text-decoration: none !important;
}

.folder-contents .ui.table .icon-align-name {
  a {
    text-decoration: none !important;
  }
}

.mobile-navigation-level1 {
  ul {
    li {
      a.btn {
        &.btn-nav {
          @extend %text-size-sm;
          color: $black !important;
        }
      }
    }
  }
}

p,
h1,
h2,
h3,
h4,
h5,
h6 {
  hyphens: none;
}
