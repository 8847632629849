.story-slider {
  position: relative;
  right: 2px;
  z-index: 10;

  .slick-list {
    overflow: hidden;
    width: 100%;
  }

  .slick-track {
    display: flex;
    transition: transform 0.5s ease;
  }

  .slick-slide {
    position: relative;
    display: flex;
    max-height: 640px;

    & > div {
      display: flex;
    }

    // GRADIENT OVERLAY
    &:after {
      content: '';
      position: absolute;
      top: -1px;
      right: -1px;
      bottom: -1px;
      left: -1px;
      background: linear-gradient(
        180deg,
        rgba(0, 0, 0, 0) 44.95%,
        rgba(0, 0, 0, 0.6) 77.2%
      ) !important;
      mix-blend-mode: darken;
    }

    img {
      &.story-image {
        width: 100%;
        height: 100%;
        aspect-ratio: 16 / 9;
        object-fit: cover;

        &.default {
          object-position: center center;
        }

        &.top_left {
          object-position: left top;
        }

        &.top_center {
          object-position: center top;
        }

        &.top_right {
          object-position: right top;
        }

        &.center_left {
          object-position: left center;
        }

        &.center_center {
          object-position: center center;
        }

        &.center_right {
          object-position: right center;
        }

        &.bottom_left {
          object-position: left bottom;
        }

        &.bottom_center {
          object-position: center bottom;
        }

        &.bottom_right {
          object-position: right bottom;
        }
      }
    }

    & .slick_slide.story-slide {
      @media (max-width: 575px) {
        height: 320px;
      }

      @media (min-width: 576px) and (max-width: 767px) {
        height: 480px;
      }

      @media (min-width: 768px) and (max-width: 1023px) {
        height: 560px;
      }

      @media (min-width: 1024px) {
        height: 640px;
      }
    }

    .story-info {
      display: flex;
      flex-direction: column;
      justify-content: flex-start;
      align-items: flex-start;
      position: absolute;
      bottom: 92px;
      left: 0;
      width: 100%;
      padding: 0 35px;
      color: white;
      text-align: left;
      z-index: 700;

      @media (max-width: 575px) {
        bottom: 55px;
      }

      @media (min-width: 576px) and (max-width: 767px) {
        bottom: 60px;
      }

      .story_title {
        font-size: 2rem;
        font-weight: bold;
        word-break: break-word;
        margin-bottom: 10px;
        color: #fff;

        @media (max-width: 575px) {
          font-size: 1.4rem;
          margin-bottom: 3px;
        }

        @media (min-width: 576px) and (max-width: 767px) {
          font-size: 1.5rem;
          margin-bottom: 3px;
        }
      }

      .story_category {
        font-size: 1.2rem;
        margin-top: 0.5rem;
        color: #fff;
        font-weight: 600;
        word-break: break-word;

        @media (max-width: 575px) {
          font-size: 0.9rem;
          line-height: 1.2;
          margin-bottom: 22px;
        }

        @media (min-width: 576px) and (max-width: 767px) {
          font-size: 1rem;
        }
      }

      .story_link {
        .button__slider {
          margin-top: 0.5rem;
          padding: 0.5rem 1rem;
          background-color: #ffcc00;
          color: #000;
          text-decoration: none;
          border-radius: 4px;

          &:hover {
            background-color: #ffa500;
          }
        }
      }

      & .btn-secondary {
        background-color: #fde70e;
        border: 2px solid transparent;
        color: #000;
        font-size: 1rem;
        line-height: normal;
        padding: 10px 15px;
        text-decoration: none !important;
        font-weight: 600;
        opacity: 1;
      }
    }
  }

  .story_navigation {
    display: flex;
    justify-content: center;
    align-items: center;
    position: absolute;
    bottom: 40px;

    .slick-dots {
      display: flex;
      justify-content: space-between;
      align-items: center;
      gap: 15px;
      padding: 0 29px;

      @media (min-width: 768px) and (max-width: 1023px) {
        padding: 0 21px;
      }

      li {
        position: relative;
        display: flex;
        width: 100%;
        height: unset;
        flex-direction: column;
        align-items: flex-start;

        &:before {
          content: '';
          position: relative;
          background-color: #f1f1ee;
          opacity: 0.3;
          height: 2px;
          width: 100%;
        }

        .progress-bar {
          position: absolute;
          top: 0;
          left: 0;
          height: 2px;
          background-color: #ffcc00;
          width: 0%;
          z-index: 1;
          transition: width 0.05s linear;
        }

        .story_navigation_slide {
          display: inline-flex;
          justify-content: center;
          align-items: center;
          font-size: 70%;
          font-weight: 600;
          color: #fff !important;
          text-decoration: none;
          opacity: 0.5;
          transition: opacity 0.3s ease;
          white-space: nowrap;

          @media (max-width: 767px) {
            display: none;
          }

          @media (min-width: 768px) and (max-width: 1023px) {
            font-size: 60%;
          }

          &:hover,
          &:focus {
            opacity: 1;
            font-weight: bold;
          }
        }

        &.slick-active {
          .story_navigation_slide {
            opacity: 1;
            font-weight: bold;
          }

          .progress-bar {
            background-color: #ffcc00;
          }
        }
      }
    }
  }
}
