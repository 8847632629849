@import '../variables';

// FADE IN
@mixin animate(
  $effect: 'fade-in',
  $time: map-get($speed, 'fast'),
  $ease: map-get($ease, 'ease-in-out'),
  $delay: $animation-delay-time,
  $mode: both
) {
  animation: $effect $time $ease $delay $mode;
  opacity: 0;
  transition:
    opacity $time $ease,
    transform $time $ease;

  // ARROW - SLIDE-IN
  @if $effect == 'slide-in-arrow' {
    @keyframes slide-in-arrow {
      0% {
        background-position: -10px 0;
        opacity: 1;
      }
      100% {
        background-position: 0 0;
        opacity: 1;
      }
    }
  }

  @if $effect == 'slide-out-arrow' {
    @keyframes slide-out-arrow {
      0% {
        background-position: 0 0;
        opacity: 1;
      }
      100% {
        background-position: -10px 0;
        opacity: 1;
      }
    }
  }

  // ROTATE
  @if $effect == 'rotate' {
    @keyframes rotate {
      from {
        transform: rotate(0deg);
      }
      to {
        transform: rotate(360deg);
      }
    }
  }

  // SCALE
  @if $effect == 'scale-in' {
    @keyframes scale-in {
      0% {
        opacity: 1;
        transform: scale(1);
      }
      100% {
        opacity: 1;
        transform: scale($animation-scale);
      }
    }
  }

  @if $effect == 'scale-out' {
    @keyframes scale-out {
      0% {
        opacity: 1;
        transform: scale($animation-scale);
      }
      100% {
        opacity: 1;
        transform: scale(1);
      }
    }
  }

  // FADE
  @if $effect == 'fade-in' {
    @keyframes fade-in {
      0% {
        opacity: 0;
      }
      100% {
        opacity: 1;
      }
    }
  }

  @if $effect == 'fade-out' {
    @keyframes fade-out {
      0% {
        opacity: 1;
      }
      100% {
        opacity: 0;
      }
    }
  }

  // SLIDE
  @if $effect == 'slide-in-left' {
    opacity: 0;
    transform: translateX(-100%);

    @keyframes slide-in-left {
      0% {
        opacity: 0;
        transform: translateX(-100%);
      }
      100% {
        opacity: 1;
        transform: translateX(0);
      }
    }
  }

  @if $effect == 'slide-out-left' {
    opacity: 1;
    transform: translateX(0);

    @keyframes slide-out-left {
      0% {
        opacity: 1;
        transform: translateX(0);
      }
      100% {
        opacity: 0;
        transform: translateX(-200%);
      }
    }
  }

  @if $effect == 'slide-in-hover' {
    opacity: 1;
    transform: translateX(0);

    @keyframes slide-in-hover {
      0% {
        opacity: 1;
        transform: translateX(0);
      }
      100% {
        opacity: 1;
        transform: translateX(0.5%);
      }
    }
  }

  @if $effect == 'slide-out-hover' {
    opacity: 1;
    transform: translateX(0.5%);

    @keyframes slide-out-hover {
      0% {
        opacity: 1;
        transform: translateX(0.5%);
      }
      100% {
        opacity: 1;
        transform: translateX(0);
      }
    }
  }

  @if $effect == 'slide-in-right' {
    opacity: 0;
    transform: translateX(100%);

    @keyframes slide-in-right {
      0% {
        opacity: 0;
        transform: translateX(100%);
      }
      100% {
        opacity: 1;
        transform: translateX(0%);
      }
    }
  }

  @if $effect == 'slide-out-right' {
    opacity: 1;
    transform: translateX(0);

    @keyframes slide-out-right {
      0% {
        opacity: 1;
        transform: translateX(0);
      }
      100% {
        opacity: 0;
        transform: translateX(100%);
      }
    }
  }

  @if $effect == 'slide-in-down' {
    @keyframes slide-in-down {
      0% {
        opacity: 0;
        transform: translateY(-100%);
      }
      100% {
        opacity: 1;
        transform: translateY(0%);
      }
    }
  }

  @if $effect == 'slide-out-up' {
    @keyframes slide-out-up {
      0% {
        opacity: 1;
        transform: translateY(0%);
      }
      100% {
        opacity: 0;
        transform: translateY(-100%);
      }
    }
  }

  @if $effect == 'slide-out-down' {
    @keyframes slide-out-down {
      0% {
        opacity: 1;
        transform: translateY(0%);
      }
      100% {
        opacity: 0;
        transform: translateY(100%);
      }
    }
  }

  @if $effect == 'slide-in-up' {
    @keyframes slide-in-up {
      0% {
        opacity: 0;
        transform: translateY(100%);
      }
      100% {
        opacity: 1;
        transform: translateY(0);
      }
    }
  }
}

// SLIDE ANIMATIONS
.scale-in {
  @include animate(scale-in, $time: map-get($speed, 'medium'));
}

.scale-out {
  @include animate(scale-out, $time: map-get($speed, 'fast'));
}

// FADES
.fade-in {
  @include animate(fade-in, $time: map-get($speed, 'medium'));
}

.fade-out {
  @include animate(fade-out, $time: map-get($speed, 'medium'));
}

// SLIDE ANIMATIONS
.slide-in-left {
  @include animate(slide-in-left);
}

.slide-out-left {
  @include animate(slide-out-left);
}

.slide-in-right {
  @include animate(slide-in-right);
}

.slide-in-hover {
  @include animate(slide-in-hover);
}

.slide-out-hover {
  @include animate(slide-out-hover);
}

.slide-out-right {
  @include animate(slide-out-right);
}

.slide-in-down {
  @include animate(slide-in-down);
}

.slide-out-up {
  @include animate(slide-out-up);
}

.slide-out-down {
  @include animate(slide-out-down);
}

.slide-in-up {
  @include animate(slide-in-up);
}

.slide-in-arrow {
  @include animate(slide-in-arrow);
}

.slide-out-arrow {
  @include animate(slide-out-arrow);
}

// SPINNER
.loading-spinner {
  display: flex;

  width: map-get($sizes, 'xl');
  height: map-get($sizes, 'xl');
  align-items: center;
  justify-content: center;
}

.loading-spinner div {
  position: absolute;
  display: block;
  width: map-get($sizes, 'lg');
  height: map-get($sizes, 'lg');
  box-sizing: border-box;
  border: 3px solid $gray-200;
  border-color: $gray-400 transparent transparent transparent;
  border-radius: 50%;
  margin: map-get($sizes, 'xxs');

  animation: loading-spinner 1500ms cubic-bezier(0.1, 0, 0.3, 1) infinite;
}

.loading-spinner div:nth-child(1) {
  animation-delay: -0.45s;
}

.loading-spinner div:nth-child(2) {
  animation-delay: -0.3s;
}

.loading-spinner div:nth-child(3) {
  animation-delay: -0.15s;
}

@keyframes loading-spinner {
  0% {
    transform: rotate(0deg);
  }
  100% {
    transform: rotate(360deg);
  }
}
