@import '../variables';

.react-aria-file-label-wrapper {
  display: flex;
  flex-direction: row;
  justify-content: space-between;
}

%file-selector-default-values {
  display: flex;
  flex-direction: row;
  align-items: center;
  justify-content: space-between;
}

.file-selector-box {
  @extend %file-selector-default-values;
  @extend %m-sm;
  @extend %p-sm;
  margin: 5px 0 15px 0;
  border: 2px solid $gray-400;
  background-color: $gray-100;
  width: 100%;

  &.has-error {
    border-color: $red;
  }
}

.file-selector {
  @extend %file-selector-default-values;
  cursor: pointer;

  button {
    cursor: pointer;

    &:focus-visible {
      outline: $gray-800 auto 1px;
    }
  }

  .file-selector-filename {
    @extend %ml-xxs;
    @extend %text-color-gray700;
    @extend %text-size-sm;
    @extend %text-weight-regular;
  }
}

.file-selector-delete {
  @extend %text-size-lg;
  border: none;
  background: none;
  color: $black;
  cursor: pointer;
  transition: color 0.3s;

  &:hover {
    color: $black;
  }
}

.field-wrapper-file {
  > [slot='description'] {
    margin-top: map-get($sizes, 'xxs');
    &:nth-last-of-type(1) {
      @extend %text-size-xxs;
      @extend %lh-md;
      @extend %text-color-gray700;
    }
  }

  label {
    max-width: 50%;
  }

  .react-aria-FieldError {
    @extend %text-color-error;
    @extend %text-size-xxs;
    max-width: 50%;
    align-self: flex-end;
    text-align: end;
  }
}