.block.schemaForm {
  &:has(.submitted) {
    background-color: map-get($colors, 'gray200');
  }

  .ui.segments,
  .ui.raised.segments,
  .ui.raised.segment {
    border: 0;
    box-shadow: none;
  }

  .ui.segment {
    padding: 0;
  }

  .ui.negative.message {
    border: 2px solid #df305b;
    // background: #fcedf0;
    padding: 15px 15px 15px 15px;
    margin: 15px 0;

    .icon {
      display: none;
    }
    .header {
      display: none;
    }
    p {
      line-height: 1.3;
      font-size: 0.75rem;
      color: #767573;
    }
  }

  .tabular.menu {
    display: none !important;
  }

  .inline.field .wrapper {
    border-bottom: 0;
  }

  .field {
    margin-bottom: 30px;
  }

  .field > .toolbar {
    position: relative;
    justify-content: flex-end;
    height: auto;
    top: 0;
    box-shadow: none;
    padding: 0;

    button {
      margin: 0;
    }
  }

  .column > .toolbar {
    left: auto;
    right: -26px;
    box-shadow: none;

    button {
      margin: 0;
    }
  }

  .addfield .column > .toolbar {
    right: -10px;
    top: 14px;
  }

  .ui.actions.segment {
    border-top: none;
    display: flex;
    gap: 30px;

    #page-edit & {
      margin: -1rem;
    }
  }

  .submitted {
    background-color: map-get($colors, 'gray200');
    @include padding($vertical: 'md', $horizontal: 'md');

    > .ui.positive {
      &.message {
        justify-content: flex-start;
        background-color: map-get($colors, 'white');

        p {
          width: 100%;
        }
      }
    }

    .ui.grid {
      padding: 10px;

      > .row {
        border-bottom: 1px solid $gray-300;
      }
    }
  }
}

.ui.modal .field-wrapper-parentFieldSet {
  display: none;
}

#page-edit .block.schemaForm .q.container {
  padding: 15px;
}

.ui.modal.field-static_text .field-wrapper-description {
  display: none;
}
