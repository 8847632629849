@import '../variables';
/**
  * Applies styles based on the screen size using media queries.
  * 
  * @param $size - The screen size to apply the styles to. Accepted values: 'xs', 'sm', 'md', 'lg', or 'custom'.
  * @param $type - The media query type. Accepted values: 'max' (default) or 'min'.
  * @param $pixels - The pixel value for the custom screen size.
*/

@mixin mq($size, $type: max, $pixels: $largest-mobile-screen) {
  $valid-size: 'xs', 'sm', 'md', 'lg', 'xl', 'custom';
  $valid-type: 'min', 'max';

  @if not index($valid-type, $type) {
    @error "Unknown media query type `#{$type}`.";
  }

  @if not index($valid-size, $size) {
    @error "Unknown screen size `#{$size}`.";
  }

  @if $size == 'xs' {
    @media screen and ($type + -width: $largest-mobile-screen) {
      @content;
    }
  } @else if $size == 'sm' {
    @media screen and ($type + -width: $largest-tablet-screen) {
      @content;
    }
  } @else if $size == 'md' {
    @media screen and ($type + -width: $smallest-desktop-screen) {
      @content;
    }
  } @else if $size == 'lg' {
    @media screen and ($type + -width: $smallest-desktop-screen) {
      @content;
    }
  } @else if $size == 'custom' {
    @media screen and ($type + -width: $pixels + px) {
      @content;
    }
  } @else {
    @content;
  }
}

@mixin responsiveMinMax($min-width: #{minSize}, $max-width: #{maxSize}) {
  @media (min-width: #{$min-width}) and (max-width: #{$max-width}) {
    @content;
  }
}
