@import '../variables';

.react-aria-ListBox {
  display: flex;
  overflow: auto;
  width: 100%;
  min-height: calculateRem(100px);
  max-height: inherit;
  max-height: calculateRem(120px);
  box-sizing: border-box;
  flex-direction: column;
  padding: calculateRem(16px);
  border: 2px solid $gray-400;
  background: $gray-100;
  color: $black;
  forced-color-adjust: none;
  outline: none;

  &[data-focus-visible] {
    outline: 2px solid $black;
    outline-offset: -1px;
  }

  &:focus-within {
    border-color: $black;
    outline: unset;
  }
}

.react-aria-ListBoxItem {
  @extend %text-size-sm;
  @extend %text-weight-regular;
  position: relative;
  display: flex;
  flex-direction: column;
  padding: 0.2rem;
  cursor: default;
  font-size: $font-size-16;
  outline: none;

  &[data-focus-visible] {
    outline: 2px solid $gray-800;
    outline-offset: -2px;
  }

  &[data-selected] {
    background: $blue-listbox;

    &[data-focus-visible] {
      outline-color: 2px solid $gray-800;
      outline-offset: -2px;
    }
  }
}

.react-aria-ListBoxItem[href] {
  cursor: pointer;
  text-decoration: none;
  -webkit-touch-callout: none;
}

.react-aria-ListBox {
  .react-aria-Section:not(:first-child) {
    margin-top: 12px;
  }

  .react-aria-Header {
    padding: 0 0.714rem;
    font-size: 1.143rem;
    font-weight: bold;
  }
}

.react-aria-ListBoxItem {
  [slot='label'] {
    font-weight: bold;
  }

  [slot='description'] {
    font-size: small;
  }
}

.react-aria-ListBoxItem {
  &[data-disabled] {
    color: $gray-500;
  }
}

.react-aria-ListBox {
  &[data-empty] {
    align-items: center;
    justify-content: center;
    font-style: italic;
  }
}
