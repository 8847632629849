@import '../variables';

.react-aria-CheckboxGroup {
  display: flex;
  flex-direction: column;
  @extend %text-color-gray800;
  gap: calculateRem(8px);

  .react-aria-FieldError {
    @extend %text-size-xxs;
    color: map-get($colors, 'error');
  }

  > [slot='description'] {
    margin-top: map-get($sizes, 'xxs');
    &:nth-last-of-type(1) {
      @extend %text-size-xxs;
      @extend %lh-md;
      @extend %text-color-gray700;
    }
  }

  &[data-orientation='horizontal'] {
    flex-direction: row;
    align-items: center;
  }
}
