@import '../variables';

.react-aria-Radio {
  display: flex;
  align-items: center;
  @extend %text-color-gray800;
  @extend %text-size-sm;
  @extend %text-weight-regular;
  forced-color-adjust: none;
  gap: calculateRem(12px);

  &:before {
    display: block;
    min-width: 1.25rem;
    width: 1.25rem;
    height: 1.25rem;
    box-sizing: border-box;
    border: 2px solid $gray-500;
    border-radius: 1.25rem;
    background: $gray-100;
    content: '';
    transition: all 200ms;
  }

  &[data-pressed]:before {
    border-color: $gray-700;
    background-color: $gray-400;
  }

  &[data-selected] {
    &:before {
      border-width: 0.429rem;
      border-color: $black;
      background-color: $white;
    }

    &[data-pressed]:before {
      border-color: $black;
      background-color: $gray-400;
    }
  }

  &[data-invalid] {
    &:before {
      border-color: map-get($colors, 'error');
    }

    &[data-pressed]:before {
      border-color: map-get($colors, 'error');
    }

    @extend %text-color-error;
  }

  &[data-disabled] {
    color: $gray-500;

    &:before {
      border-color: $gray-400;
      background: $gray-200;
    }
  }
}

.react-aria-RadioGroup {
  display: flex;
  flex-direction: column;
  @extend %text-color-gray800;
  gap: calculateRem(8px);

  .react-aria-FieldError {
    @extend %text-size-xxs;
    color: map-get($colors, 'error');
  }

  > [slot='description'] {
    margin-top: map-get($sizes, 'xxs');
    &:nth-last-of-type(1) {
      @extend %text-size-xxs;
      @extend %lh-md;
      @extend %text-color-gray700;
    }
  }

  &[data-orientation='horizontal'] {
    flex-direction: row;
    align-items: center;
  }
}
