@import '../_variables.scss';

/**
 * Mixin to generate margin classes.
 * @param {String} $key - The suffix for the class, typically representing the size of the margin.
 * @param {Number} $value - The size of the margin to be applied.
 *
 * The mixin generates classes for setting margins on all sides (m-*), top (mt-*), right (mr-*), bottom (mb-*), left (ml-*), 
 * and the x (mx-*) and y (my-*) axes.
 */
@mixin generate-margins($key, $value) {
  .mt-#{$key} {
    margin-top: $value;
  }
  .mr-#{$key} {
    margin-right: $value;
  }
  .mb-#{$key} {
    margin-bottom: $value;
  }
  .ml-#{$key} {
    margin-left: $value;
  }
  .mx-#{$key} {
    margin: 0 $value;
  }
  .my-#{$key} {
    margin: $value 0;
  }
  .m-#{$key} {
    margin: $value $value $value $value;
  }

  %mt-#{$key} {
    margin-top: $value;
  }
  %mr-#{$key} {
    margin-right: $value;
  }
  %mb-#{$key} {
    margin-bottom: $value;
  }
  %ml-#{$key} {
    margin-left: $value;
  }
  %mx-#{$key} {
    margin: 0 $value;
  }
  %my-#{$key} {
    margin: $value 0;
  }
  %m-#{$key} {
    margin: $value $value $value $value;
  }
}

@each $key, $value in $sizes {
  @include generate-margins($key, $value);
}

/**
 * Mixin to generate padding classes.
 * @param {String} $key - The suffix for the class, typically representing the size of the padding.
 * @param {Number} $value - The size of the padding to be applied.
 *
 * The mixin generates classes for setting paddings on all sides (p-*), top (pt-*), right (pr-*), bottom (pb-*), left (pl-*), 
 * and the x (px-*) and y (py-*) axes.
 */
@mixin generate-paddings($key, $value) {
  .pt-#{$key} {
    padding-top: $value;
  }
  .pr-#{$key} {
    padding-right: $value;
  }
  .pb-#{$key} {
    padding-bottom: $value;
  }
  .pl-#{$key} {
    padding-left: $value;
  }
  .px-#{$key} {
    padding: 0 $value;
  }
  .py-#{$key} {
    padding: $value 0;
  }
  .p-#{$key} {
    padding: $value $value $value $value;
  }

  %pt-#{$key} {
    padding-top: $value;
  }
  %pr-#{$key} {
    padding-right: $value;
  }
  %pb-#{$key} {
    padding-bottom: $value;
  }
  %pl-#{$key} {
    padding-left: $value;
  }
  %px-#{$key} {
    padding: 0 $value;
  }
  %py-#{$key} {
    padding: $value 0;
  }
  %p-#{$key} {
    padding: $value $value $value $value;
  }
}

@each $key, $value in $sizes {
  @include generate-paddings($key, $value);
}

/**
 * Mixin to apply padding to elements.
 * @param {Number} $top - The size of the padding to be applied to the top of the element.
 * @param {Number} $right - The size of the padding to be applied to the right of the element.
 * @param {Number} $bottom - The size of the padding to be applied to the bottom of the element.
 * @param {Number} $left - The size of the padding to be applied to the left of the element.
 * @param {Number} $vertical - The size of the padding to be applied vertically (top and bottom) to the element.
 * @param {Number} $horizontal - The size of the padding to be applied horizontally (right and left) to the element.
 *
 * This mixin allows you to control padding on an element in a granular way. You can set padding for top, right, bottom, and left individually, 
 * or set it vertically and horizontally.
 * Usage example: @include padding('sm', 'md');
 */
@mixin padding(
  $top: null,
  $right: null,
  $bottom: null,
  $left: null,
  $vertical: null,
  $horizontal: null
) {
  @if ($vertical == null) {
    @if ($horizontal == null) {
      @if ($top == null) {
        padding-top: map-get($sizes, $top);
      }
      @if ($right) {
        padding-right: map-get($sizes, $right);
      }
      @if ($bottom == null) {
        padding-bottom: map-get($sizes, $bottom);
      }
      @if ($left == null) {
        padding-left: map-get($sizes, $left);
      } @else if ($top, $right, $bottom, $left) {
        padding: map-get($sizes, $top)
          map-get($sizes, $right)
          map-get($sizes, $bottom)
          map-get($sizes, $left);
      }
    }
  }
  @if ($vertical, $horizontal) {
    padding: map-get($sizes, $vertical) map-get($sizes, $horizontal);
  }
}

/**
 * Mixin to apply margin to elements.
 * @param {Number} $top - The size of the margin to be applied to the top of the element.
 * @param {Number} $right - The size of the margin to be applied to the right of the element.
 * @param {Number} $bottom - The size of the margin to be applied to the bottom of the element.
 * @param {Number} $left - The size of the margin to be applied to the left of the element.
 * @param {Number} $vertical - The size of the margin to be applied vertically (top and bottom) to the element.
 * @param {Number} $horizontal - The size of the margin to be applied horizontally (right and left) to the element.
 *
 * This mixin allows you to control margin on an element in a granular way. You can set margin for top, right, bottom, and left individually, 
 * or set it vertically and horizontally.
 * Usage example: @include ('sm', 'md');
 */
@mixin margin(
  $top: null,
  $right: null,
  $bottom: null,
  $left: null,
  $vertical: null,
  $horizontal: null
) {
  @if ($vertical == null) {
    @if ($horizontal == null) {
      @if ($top == null) {
        margin-top: map-get($sizes, $top);
      }
      @if ($right) {
        margin-right: map-get($sizes, $right);
      }
      @if ($bottom == null) {
        margin-bottom: map-get($sizes, $bottom);
      }
      @if ($left == null) {
        margin-left: map-get($sizes, $left);
      } @else if ($top, $right, $bottom, $left) {
        margin: map-get($sizes, $top)
          map-get($sizes, $right)
          map-get($sizes, $bottom)
          map-get($sizes, $left);
      }
    }
  }
  @if ($vertical, $horizontal) {
    margin: map-get($sizes, $vertical) map-get($sizes, $horizontal);
  }
}
