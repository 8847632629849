.block.iframe.default {
  position: relative;
  padding-bottom: 56.25%; /* 16/9 */
  margin-bottom: 0;

  .frame {
    width: 100%;
    height: 100%;
    position: absolute;
    overflow: hidden;
  }
}
.block.iframe .frame {
  border: none;
}
.block-editor-iframe .block.iframe {
  .error {
    padding: 1em;
    height: 300px;
    background-color: #ce1a2b;
    display: flex;
    flex-direction: column;
    justify-content: center;
    margin: 0 auto 0 auto;

    p {
      color: $white;
      hyphens: none;
    }
  }
  .toolbar-inner {
    max-width: 350px;
    justify-content: space-between;

    > div {
      display: flex;
    }

    button,
    input {
      border: none;
      background: none;

      &:focus-visible {
        outline: none;
      }
      &::placeholder {
        font-style: italic;
        font-weight: 300;
      }
    }

    button {
      &:hover {
        cursor: pointer;
      }
      &:disabled {
        cursor: not-allowed;
      }
    }
  }
}
