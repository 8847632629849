.crm-form-block {
    .crm-form-iframe {
        width: 100%;
        border: none;
        display: block;
        overflow: hidden;
        transition: height 0.2s ease-in-out;
    }

    .crm-form-overlay {
        position: absolute;
        top: 0;
        left: 0;
        width: 100%;
        height: 100%;
        z-index: 2;
        cursor: default;
        display: flex;
        flex-direction: column;
        align-items: center;
        justify-content: center;
        text-align: center;
        pointer-events: auto;
    }
}