$smallSpacing: map-get($sizes, 'xs');
$middleSpacing: map-get($sizes, 'sm');
$largeSpacing: map-get($sizes, 'md');

.content-area {
  display: grid;

  background-color: $white;
  grid-template-areas: 'header content-slot';
  grid-template-columns: 340px 1fr;
  grid-template-rows: 1fr;

  @media screen and (max-width: $largest-tablet-screen) {
    grid-template-areas:
      'header'
      'content-slot';
    grid-template-columns: 1fr;
    grid-template-rows: 1fr;
  }
}

.header-slot {
  grid-area: header;
}

.content-slot {
  display: grid;
  overflow: hidden;
  grid-area: content-slot;
  grid-template-areas:
    'hero'
    'breadcrumbs'
    'document'
    'locationmap'
    'footer';
  grid-template-rows: 1fr;
  position: relative;

  @media screen and (max-width: $largest-tablet-screen) {
    grid-template-areas:
      'hero'
      'breadcrumbs'
      'document'
      'locationmap'
      'footer';
    grid-template-columns: 1fr;
    grid-template-rows: 1fr;
  }
}

.hero-slot,
.start-slider-slot {
  min-width: unset;
  grid-area: hero;
}

.breadcrumbs {
  grid-area: breadcrumbs;
}

.locationmap {
  grid-area: locationmap;

  h2,
  p {
    margin-right: 5%;
    margin-left: 50px;
  }
}

#page-document {
  display: grid;
  margin-right: map-get($sizes, 'xl');

  margin-left: map-get($sizes, 'xl');
  grid-template-columns: 1fr;
  justify-items: start;

  &:has(aside) {
    align-items: start;
    grid-template-areas: 'content aside';
    grid-template-columns: 9fr 3fr;
    grid-template-rows: 1fr;

    justify-items: start;

    @media screen and (max-width: $large-desktop-screen) {
      margin-right: map-get($sizes, 'xl');

      margin-left: map-get($sizes, 'xl');
      grid-template-areas:
        'content'
        'aside';
      grid-template-columns: 1fr;
    }

    @media screen and (min-width: $large-desktop-screen) {
      margin-right: 5%;
      margin-left: map-get($sizes, 'xl');
      column-gap: 10%;
    }

    @media screen and (max-width: $largest-tablet-screen) {
      margin-right: map-get($sizes, 'xl');
      margin-left: map-get($sizes, 'xl');
    }

    @media screen and (max-width: $largest-mobile-screen) {
      margin-right: map-get($sizes, 'sm');
      margin-left: map-get($sizes, 'sm');
    }
  }

  @media screen and (min-width: $large-desktop-screen) {
    margin-right: 5%;
    margin-left: map-get($sizes, 'xl');
    column-gap: 10%;
  }

  @media screen and (max-width: $largest-tablet-screen) {
    margin-right: map-get($sizes, 'lg');
    margin-left: map-get($sizes, 'lg');
  }

  @media screen and (max-width: $largest-mobile-screen) {
    margin-right: map-get($sizes, 'sm');
    margin-left: map-get($sizes, 'sm');
  }

  .blocks-group-wrapper {
    width: 100%;

    @media screen and (max-width: $largest-mobile-screen) {
      // background-color: skyblue;
      margin: $smallSpacing 0 $smallSpacing 0;
      padding: $smallSpacing 0 $smallSpacing 0;
    }

    @media screen and (min-width: $largest-mobile-screen) {
      // background-color: palegreen;
      margin: $middleSpacing 0 $middleSpacing 0;
      padding: $middleSpacing 0 $middleSpacing 0;
    }

    @media screen and (min-width: $smallest-desktop-screen) {
      // background-color: blueviolet;
      margin: $largeSpacing 0 $largeSpacing 0;
      padding: $largeSpacing 0 $largeSpacing 0;
    }

    @media screen and (min-width: $large-desktop-screen) {
      // background-color: goldenrod;
      margin: $largeSpacing 0 $largeSpacing 0;
      padding: $largeSpacing 0 $largeSpacing 0;
    }
  }
}

.content {
  max-width: var(--layout-container-width);
  width: 100%;
  grid-area: content;
  width: 100%;
  display: grid;
  grid-template-columns: repeat(auto-fit, minmax(0, 1fr));
  grid-template-rows: auto;
}

aside {
  display: grid;

  min-width: 250px;
  max-width: 500px;
  grid-area: aside;
  grid-template-areas:
    'sidebar'
    'contact';
  grid-template-columns: minmax(12.5rem, 21.25rem);
  grid-template-rows: 1fr;

  @media screen and (max-width: $large-desktop-screen) {
    max-width: unset;
    grid-template-areas:
      'sidebar'
      'contact';
    grid-template-columns: 1fr;
  }
}

.sidebar-navigation-container {
  grid-area: sidebar;
}

.contact-content-sidebar-wrapper {
  grid-area: contact;
}

.footer {
  align-content: flex-end;
  grid-area: footer;
}
