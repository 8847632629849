// @import '../variables';

.web25__edubox {
  position: relative;
  display: grid;
  z-index: 0;
  gap: map-get($sizes, 'md');
  grid-template-columns: 1fr;
  grid-template-rows: 1fr;
  @include padding($top: 'xl', $right: 'none', $bottom: 'md', $left: 'none');
  @include margin($top: 'xl', $right: 'none', $bottom: 'xl', $left: 'none');

  .fullbleed-edubox-wrapper {
    position: absolute;
    z-index: -1;
    left: -(map-get($sizes, 'xl'));
    top: 0;
    bottom: 0;

    background: map-get($colors, 'gray200');
    background: url('../../../public/edubox-bg.png');
    background-repeat: no-repeat;
    background-size: cover;
    background-position: bottom right;
    width: 100vmax;
  }

  h2 {
    margin-bottom: map-get($sizes, 'sm');
  }

  h2 {
    margin-bottom: map-get($sizes, 'sm');
  }

  &-keydatas {
    z-index: 0;
    @include padding($vertical: 'md', $horizontal: 'md');
    border: 1px solid map-get($colors, 'gray400');

    background-color: map-get($colors, 'white');

    > dl {
      display: flex;
      flex-direction: row;
      flex-wrap: wrap;
      justify-content: flex-start;

      div {
        display: flex;
        flex: 0 0 33.33%;
        flex-direction: column;
        align-items: flex-start;
        justify-content: flex-start;
        border-bottom: 1px solid map-get($colors, 'gray400');

        @include mq($size: 'lg', $type: 'max') {
          flex: 0 0 50%;
        }

        @include mq($size: 'sm', $type: 'max') {
          flex: 0 0 100%;
        }

        @include padding($vertical: 'sm', $horizontal: 'none');

        dt,
        dd {
          @include font-size($font-size-15);
          padding-right: 5px;
          margin: 0;

          &.documents-container {
            display: flex;
            flex-wrap: wrap;

            & > div.documents-column {
              margin: 0;
              padding: 0;
              flex: 1 1 120px;
              border-bottom: 0;

              & > div {
                padding: 0;
                border-bottom: 0;
              }
            }
          }
        }

        dt {
          font-weight: map-get($font-weights, 'semibold');
        }
      }
    }
  }

  &-infotext {
    position: relative;
    display: flex;
    align-items: flex-start;
    justify-content: flex-start;

    @include font-size($font-size-15);

    &::before {
      position: absolute;
      top: 4px;
      width: 16px;
      height: 16px;
      background-image: url(data:image/svg+xml;base64,PHN2ZyBpZD0iTGF5ZXJfMSIgZGF0YS1uYW1lPSJMYXllciAxIiB4bWxucz0iaHR0cDovL3d3dy53My5vcmcvMjAwMC9zdmciIHZpZXdCb3g9IjAgMCAyNiAyNiIgd2lkdGg9IjIyIiBoZWlnaHQ9IjIyIj48ZGVmcz48c3R5bGU+LmNscy0xe2ZpbGw6bm9uZTtzdHJva2U6IzRjNGM0Y30uY2xzLTJ7ZmlsbDojNGM0YzRjO2ZpbGwtcnVsZTpldmVub2RkfTwvc3R5bGU+PC9kZWZzPjxwYXRoIGNsYXNzPSJjbHMtMSIgZD0iTTE5IDEwYTkgOSAwIDEgMS05LTkgOSA5IDAgMCAxIDkgOXoiIHRyYW5zZm9ybT0idHJhbnNsYXRlKC0uNTQgLS41NCkiLz48cGF0aCBjbGFzcz0iY2xzLTIiIGQ9Ik0xMi4xOSAxNC43N2wtLjE3LjY3LTEuMTguNDRhMi44OCAyLjg4IDAgMCAxLTEgLjE2IDIuMDggMi4wOCAwIDAgMS0xLjQtLjQ0IDEuNDEgMS40MSAwIDAgMS0uNDktMS4xMUE0Ljg0IDQuODQgMCAwIDEgOCAxNGMwLS4xOC4wNy0uMzkuMTItLjYybC42Mi0yLjE4Yy4wNS0uMjEuMS0uNDEuMTMtLjU5YTIuNTIgMi41MiAwIDAgMCAuMDYtLjUyLjgyLjgyIDAgMCAwLS4xNy0uNTggMSAxIDAgMCAwLS42Ni0uMTYgMS43MSAxLjcxIDAgMCAwLS40OS4wN2wtLjQzLjE0LjE3LS42N2MuNC0uMTYuNzktLjMgMS4xNi0uNDJhMy4zIDMuMyAwIDAgMSAxLS4xOCAyIDIgMCAwIDEgMS4zNy40MyAxLjQ0IDEuNDQgMCAwIDEgLjQ4IDEuMTJ2LjVhMy4yMyAzLjIzIDAgMCAxLS4xMi42NmwtLjYyIDIuMTdjLS4wNS4xOC0uMDkuMzgtLjEzLjZhMy4wNyAzLjA3IDAgMCAwLS4wNi41MS43My43MyAwIDAgMCAuMTkuNTkgMS4xNiAxLjE2IDAgMCAwIC42OC4xNSAxLjg4IDEuODggMCAwIDAgLjUtLjA3Yy4yLS4xLjMtLjE0LjM5LS4xOHptLjE1LTkuMTJhMS4yNSAxLjI1IDAgMCAxLS40MyAxIDEuNDMgMS40MyAwIDAgMS0xIC40IDEuNDYgMS40NiAwIDAgMS0xLS40IDEuMyAxLjMgMCAwIDEgMC0xLjkzIDEuNDcgMS40NyAwIDAgMSAxLS40MSAxLjQ0IDEuNDQgMCAwIDEgMSAuNDEgMS4yNSAxLjI1IDAgMCAxIC40My45M3oiIHRyYW5zZm9ybT0idHJhbnNsYXRlKC0uNTQgLS41NCkiLz48L3N2Zz4=);
      background-repeat: no-repeat;
      background-size: auto;
      content: '';
    }

    p {
      display: inline-block;
      padding-left: 1.5rem;
    }
  }

  &-buttons {
    display: flex;
    flex-direction: row;
    align-content: flex-start;
    align-items: flex-start;
    justify-content: space-between;
    gap: calculateRem(map-get($sizes, 'lg'));

    a {
      text-align: center;
    }

    @include mq($size: 'sm', $type: 'max') {
      flex-direction: column;
      gap: calculateRem(map-get($sizes, 'md'));
    }
  }

  &-mobile-nav {
    position: fixed;
    bottom: 0;
    display: none;
    align-items: center;
    justify-content: space-around;
    background-color: red;
  }
}
