.block.__button,
.block-editor-__button {
  text-align: left;
  display: inline-flex;
  width: max-content;
  margin-right: 10px;

  &.has--variation--black .ui.button {
    @include button-styles(
      $white,
      $black,
      $black,
      $yellow,
      transparent,
      transparent
    );
  }

  &.has--variation--white .ui.button {
    @include button-styles($black, $white, $black, $white, $black, $black);
    border: 2px solid $gray-400;
  }
}

.block.__button,
.block-editor-__button {
  text-align: left;
  display: inline-flex;
  width: max-content;
  margin-right: 10px;

  &.has--variation--black .ui.button {
    @include button-styles(
      $white,
      $black,
      $black,
      $yellow,
      transparent,
      transparent
    );
  }

  &.has--variation--white .ui.button {
    @include button-styles($black, $white, $black, $white, $black, $black);
    border: 2px solid $gray-400;
  }
}

.block.__button {
  .button.container {
    margin: 0;

    button {
      margin-right: map-get($sizes, 'xs');
      margin-bottom: map-get($sizes, 'xs');
    }
  }
}
