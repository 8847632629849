@import '../_variables.scss';

.q.icon {
  &.icon--color-gray {
    color: $gray-800;
    pointer-events: all;
  }

  &.icon--color-black {
    color: $black;
    pointer-events: all;
  }

  &.icon--color-yellow {
    color: $yellow;
    pointer-events: all;
  }

  &.icon--color-red {
    color: $red;
    pointer-events: all;
  }

  &.icon--color-green {
    color: $green;
    pointer-events: all;
  }

  &.icon--color-blue {
    color: $lightblue;
    pointer-events: all;
  }

  &.icon-sizeXS {
    block-size: map-get($icon-sizes, 'XS');
    inline-size: map-get($icon-sizes, 'XS');
  }

  &.icon--sizeS {
    block-size: map-get($icon-sizes, 'S');
    inline-size: map-get($icon-sizes, 'S');
  }

  &.icon--sizeM {
    block-size: map-get($icon-sizes, 'M');
    inline-size: map-get($icon-sizes, 'M');
  }

  &.icon--sizeL {
    block-size: map-get($icon-sizes, 'L');
    inline-size: map-get($icon-sizes, 'L');
  }

  &.icon--sizeXL {
    block-size: map-get($icon-sizes, 'XL');
    inline-size: map-get($icon-sizes, 'XL');
  }

  &.icon--sizeXXL {
    block-size: map-get($icon-sizes, 'XXL');
    inline-size: map-get($icon-sizes, 'XXL');
  }

  &.icon {
    display: inline-block;
    fill: currentColor;
  }
}
