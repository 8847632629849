@import '../variables';

/**
* @description The @each directive in SCSS is used to loop over lists or maps.
* It takes a list or map and generates CSS from the templates you provide.
* @param {Map} $colors - see map in _variables.
* @param {String} $color-name - a variable that holds the key of each pair in the $colors map.
* @param {String} $color-value - a variable that holds the value of each pair in the $colors map.
*/
@each $color-name, $color-value in $colors {
  %bg-#{$color-name} {
    background-color: $color-value;
  }
  .bg-#{$color-name} {
    background-color: $color-value;
  }
}
