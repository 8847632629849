@import '../variables';

.info_event {
  overflow: hidden;
  box-sizing: border-box;
  @extend %mt-xl;

  .teaser-listing {
    display: flex;
    flex-wrap: wrap;
    box-sizing: border-box;

    li.teaser {
      display: flex;
      box-sizing: border-box;
      flex: 1;
      list-style-type: none;
      @include padding(
        $top: 'none',
        $right: 'md',
        $bottom: 'none',
        $left: 'none'
      );

      a.teaser-link {
        display: flex;
        flex: 1;
        align-items: center;
        justify-content: space-between;

        color: map-get($colors, 'primary') !important;
        text-decoration: none;

        @extend %text-size-sm;
        @extend %text-weight-regular;

        .teaser-wrapper {
          @include padding($vertical: 'md', $horizontal: 'md');

          @media screen and (max-width: $largest-mobile-screen) {
            font-size: 16px;
            padding: map-get($sizes, 'md') map-get($sizes, 'none');
            flex-direction: row;
          }

          span {
            flex: 0 0 33%;

            &.date {
              @media screen and (max-width: $largest-mobile-screen) {
                padding-right: 5px;
              }
            }

            &.place {
              padding-right: map-get($sizes, 'md');

              @media screen and (max-width: $largest-mobile-screen) {
                padding-right: 0;
              }
            }
          }
        }
      }
    }
  }

  &-roominfo {
    display: block;
    @extend %text-weight-bold;
    @extend %text-color-gray700;
    @extend %text-size-xs;
  }
}
