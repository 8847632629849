.cta-slot-container {
  position: relative;
  height: 100%;

  .cta-button {
    position: absolute;
    display: flex;

    width: 145px;
    height: 145px;
    flex-direction: column;
    align-items: center;
    justify-content: center;

    padding: map-get($sizes, 'lg');
    border: unset;
    border-radius: 50%;
    background: $white;

    box-shadow: 2px 2px 5px 2px $gray-800;
    transition-duration: map-get($animation-time, 'medium');
    transition-property: margin-right, width, height, right, bottom, box-shadow,
      transform, background-color;

    &:hover {
      background-color: $yellow;
      box-shadow: 2px 2px 5px 0px $gray-800;
      transform: translateY(-(map-get($sizes, 'md')));
    }

    &[data-hovered] {
      outline: 2px solid $yellow-3;
    }

    &[data-pressed] {
      box-shadow: none;
      outline: none;
    }

    &.placeholder {
      border: 1px dashed $gray-700;
      background: #ffffff77;
      box-shadow: none;
      color: $gray-900;
      cursor: pointer;
      text-align: center;
    }

    @media only screen and (max-width: $largest-mobile-screen) {
      right: 5%;
      bottom: 15%;
      font-size: calc($font-size-24 + 2px);
      transform: bottom(10%);
    }

    @media only screen and (min-width: $largest-mobile-screen) {
      right: 5%;
      bottom: 5%;
      width: 200px;
      height: 200px;
    }

    @media only screen and (min-width: $largest-tablet-screen) {
      right: 10%;
      bottom: 2%;
    }

    @media only screen and (min-width: $large-desktop-screen) {
      margin-right: calc(15vw + 1%);
    }

    &.hide-desktop {
      display: none;

      &:not(.hide-mobile) {
        @media only screen and (max-width: $largest-mobile-screen) {
          display: unset;
        }
      }
    }

    &.hide-mobile {
      @media only screen and (max-width: $largest-mobile-screen) {
        display: none;
      }
    }
  }

  .cta-button-inheritance {
    position: absolute;
    bottom: 150px;
    left: 75%; /* must be same as CTA-Button */

    width: 145px;
    background-color: rgba(255, 255, 255, 0.7);

    a {
      line-height: 100%;
    }

    &:after {
      position: absolute;
      right: 0;
      bottom: -10px;
      left: 0;
      width: 0;
      height: 0;
      border-top: 10px solid rgba(255, 255, 255, 0.7);
      border-right: 10px solid transparent;
      border-left: 10px solid transparent;
      margin: 0 auto;
      content: '';
    }
  }

  a.cta-button,
  button.cta-button {
    display: flex;
    flex-direction: column;
    align-items: center;
    justify-content: center;
    text-decoration: none;
    @include padding($vertical: 'xxs', $horizontal: 'xxs');

    h2 {
      @extend %text-color-gray800;
      @extend %text-size-sm;
      @extend %text-weight-medium;
      text-align: center;

      padding: map-get($sizes, 'md');
      border-bottom: none;

      @media only screen and (min-width: $largest-mobile-screen) {
        // font-size: $font-size-24;
        font-size: 122.25%;
      }
    }
  }
}
