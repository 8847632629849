@import '../variables';

$font-type: (
  'inter': $fontInter,
  'spectral': $fontSpectral,
);

/**
   * SCSS Map for Font Types 
   * Replace this with your actual map details
   * $font-type: ("key1" : "value1", "key2" : "value2");
   * 
   * This loop goes through the SCSS map to dynamically generate CSS classes
   * for each font type.
   */
@each $key, $val in $font-type {
  .font-#{$key} {
    font-family: #{$val};
  }
}

// CONVERT PIXEL FONT-SIZE TO REM
@mixin font-size($size) {
  // font-size: $size;
  font-size: calculateRem($size);
}

// TEXT COLOR
@each $color-name, $color-value in $colors {
  %text-color-#{$color-name} {
    color: $color-value;
  }
  .text-color-#{$color-name} {
    color: $color-value;
  }
}

// TEXT SIZE
@each $size-name, $size-value in $font-styles {
  .text-size-#{$size-name} {
    @include font-size(map-get($map: $size-value, $key: 'font-size'));
    line-height: map-get($size-value, 'line-height');
  }
  %text-size-#{$size-name} {
    @include font-size(map-get($map: $size-value, $key: 'font-size'));
    line-height: map-get($size-value, 'line-height');
  }
}

// font-weight
@each $font-weight-name, $font-weight-value in $font-weights {
  %text-weight-#{$font-weight-name} {
    font-weight: $font-weight-value;
  }
  .text-weight-#{$font-weight-name} {
    font-weight: $font-weight-value;
  }
}

// LINE HEIGHT
@each $size-name, $size-value in $line-heights {
  %lh-#{$size-name} {
    line-height: $size-value;
  }
  .lh-#{$size-name} {
    line-height: $size-value;
  }
}
