// HEADING BOTTOM BORDERS
%yellowBorder {
  &::after {
    display: flex;
    width: calc(map-get($sizes, 'xs') * 8); // 80px
    height: calc(map-get($sizes, 'xxs') - 1px);
    justify-content: center;
    margin-top: map-get($sizes, 'sm');
    content: '';
    @extend %bg-secondary;
  }
}

%yellowBorderCentered {
  &.centered {
    display: flex;
    flex-direction: column;
    align-items: center;
    justify-content: center;
    text-align: center;

    &::after {
      width: 150px;
    }
  }
}

%borderBottomColor {
  padding-bottom: calc(map-get($sizes, 'xs') - 2px);
  border-bottom: 4px solid $gray-400;
}

h1,
%h1,
h1.documentFirstHeading,
#page-document .blocks-group-wrapper > h1.documentFirstHeading,
#page-edit .block-editor-title h1,
#page-add .block-editor-title h1 {
  @extend %text-color-primary;
  @extend %text-size-xxl;
  @extend %text-weight-medium;
  @extend %lh-md;
  @extend %yellowBorder;
  @extend %yellowBorderCentered;
  display: block;
  width: 100%;
  padding: 0;
  margin-right: unset;
  margin-bottom: map-get($sizes, 'md');
  margin-left: unset;

  &.no-border {
    &::after {
      content: unset;
    }
  }

  span {
    font-weight: 500 !important;
  }

  @media screen and (max-width: $largest-mobile-screen) {
    font-size: calc($font-size-22 + 2px);
  }
}

h2,
%h2,
.headline,
.block.listing h2,
.block h2.headline,
#highlight-title-h2 {
  @extend %text-color-primary;
  @extend %text-size-lg;
  @extend %text-weight-semibold;
  @extend %lh-md;
  @extend %borderBottomColor;

  margin-top: map-get($sizes, 'md');
  margin-bottom: map-get($sizes, 'md');
}

h3,
%h3,
h3[id^='h3-'] {
  @extend %text-color-primary;
  @extend %text-size-smd;
  @extend %text-weight-semibold;
  @extend %lh-xxs;
}

h4,
%h4 {
  @extend %text-color-primary;
  @extend %text-size-md;
  @extend %text-weight-semibold;
  @extend %lh-xxs;
}

h5,
%h5 {
  @extend %text-color-primary;
  @extend %text-size-xs;
  @extend %text-weight-semibold;
  @extend %lh-xxs;
}

h6,
%h6 {
  @extend %text-size-xxs;
  @extend %text-weight-semibold;
  @extend %lh-xxs;
}

// DEFAULT FONT STYLES
p,
%p,
#page-document .blocks-group-wrapper > p:not(.documentDescription),
.content p {
  @extend %text-size-sm;
  @extend %text-color-gray800;
  @extend %text-weight-regular;
  @extend %lh-xl;

  &.centered {
    text-align: center;
  }

  > a:not(.teaser-link) {
    @extend %text-weight-medium;
    text-decoration: underline !important;

    &:hover {
      color: $black !important;
    }
  }
}

a,
%a {
  color: inherit;
  font-size: inherit;
  font-weight: inherit;
  text-decoration: underline;

  :has(> .ui.button) {
    text-decoration: none !important;
  }

  &:hover {
    @extend %text-color-primary;
    text-decoration: underline;
  }
}

p,
%p {
  margin-top: map-get($sizes, 'sm');
  margin-bottom: map-get($sizes, 'lg');

  &.centered {
    text-align: center;
  }

  > a {
    @extend %text-weight-medium;

    &:hover {
      color: inherit;
    }
  }

  @media screen and (max-width: $largest-mobile-screen) {
    font-size: $font-size-15;
  }
}

* > .centered {
  @extend %yellowBorderCentered;

  &:not(h1) {
    &::after {
      width: 10%;
    }
  }
}

// LISTINGS

.content ul:not(.block, .teaser-listing, .teaser-col-3, .teaser-col-4),
.content ol:not(.block, .teaser-listing),
.ol.ui.list,
.ui.ordered.list,
.ui.ordered.list .list,
ol.ui.list ol {
  padding-left: $defaultFontSize;
  margin-left: 0;
  padding-bottom: map-get($sizes, 'lg');

  li,
  li a {
    @extend p;
    margin-top: 0;
    margin-bottom: 0;
    padding-left: map-get($sizes, 'xxs');
  }
}

// CONSOLIDATED CLASSES
summary,
%summary,
.summary,
.lead,
%lead,
#page-edit .documentDescription,
#page-add .documentDescription,
#page-document .blocks-group-wrapper .documentDescription,
.block.introduction .block-container p {
  display: block;
  @extend %text-color-gray700;
  @extend %text-size-lg;
  @extend %text-weight-regular;
  @extend %lh-xl;
  @extend %mb-xl;

  opacity: 1;

  > a {
    font-weight: inherit;

    &:hover {
      @extend %text-color-primary;
    }
  }

  @media screen and (max-width: $largest-mobile-screen) {
    font-size: $font-size-18;
  }
}

strong {
  @extend %text-weight-bold;
}

code {
  @extend %text-color-error;
  font-size: inherit;
}

.no-border {
  border-bottom: unset;
}

.has-b-border {
  @extend %borderBottomColor;
}

.has-yellow-b-border {
  @extend %yellowBorder;
}

sup,
sub {
  @extend %text-size-xxs;
  @extend %text-weight-medium;
}
