#page-document .blocks-group-wrapper > .follow-us-wrapper,
.follow-us-wrapper {
  width: 100%;
  margin-top: calculateRem(50px);

  h2 {
    padding-bottom: 8px;
    border-bottom: 4px solid #deded9;
  }
}

.follow-us-container {
  display: flex;
  align-items: center;
  justify-content: space-between;
}

.follow-us-grid {
  display: grid;
  flex-grow: 1;
  padding: 0;
  gap: 30px;
  grid-template-columns: repeat(3, minmax(140px, 1fr));
}

.follow-us-item {
  display: flex;
  align-items: center;
  justify-content: flex-start;
  padding: 0;

  &:hover {
    & .q.icon,
    .follow-us-link {
      color: #000;
    }
  }
}

.follow-us-link {
  margin-left: 6px;
  color: #000;
  color: #4c4c4c;
  font-size: $font-size-18;
  font-weight: $font-weight-500;
  text-decoration: none;
  text-decoration: underline;
}

.follow-us-logo > a {
  display: flex;
  min-width: 200px;
  align-items: center;
  justify-content: center;
}

@media (max-width: 768px) {
  .follow-us-container {
    flex-direction: column;
    align-items: flex-start;
  }

  .follow-us-grid {
    width: 100%;
    grid-template-columns: 1fr;
  }

  .follow-us-logo > a {
    justify-content: start;
    padding-left: 0;
    margin-top: 20px;
  }
}
