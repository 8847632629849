#page-document .blocks-group-wrapper > .newsletter-form,
.newsletter-form {
  width: 100%;
  margin-top: calculateRem(50px);

  h2 {
    padding-bottom: 8px;
    border-bottom: 4px solid #deded9;
  }

  button {
    width: max-content;

    @media only screen and (max-width: $largest-mobile-screen) {
      width: 100%;
    }
  }

  .checkbox-group {
    .title {
      display: block;
      margin-bottom: calculateRem(20px);
      color: #4c4c4c;
      font-weight: 400;
      font-size: 1rem;
      line-height: 1;
    }

    .react-aria-Checkbox {
      margin-top: calculateRem(10px);
      margin-bottom: calculateRem(10px);
    }
  }
}
