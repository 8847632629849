.web25__gototop {
  position: fixed;
  bottom: 40px;
  right: 40px;
  z-index: 2300;
  opacity: 0;
  cursor: pointer;

  -webkit-transition: opacity 0.25s ease-in-out;
  transition: opacity 0.25s ease-in-out;

  &.visible {
    opacity: 0.5;
  }

  &::before {
    background-image: url(data:image/svg+xml;base64,PHN2ZyBpZD0iTGF5ZXJfMSIgeG1sbnM9Imh0dHA6Ly93d3cudzMub3JnLzIwMDAvc3ZnIiB2aWV3Qm94PSIwIDAgNTEgNTEiPjxzdHlsZT4uc3Qwe29wYWNpdHk6Ljd9LnN0MXtmaWxsOiM1NTV9LnN0MntmaWxsOiNmZmZ9PC9zdHlsZT48dGl0bGU+RTQ0RUQwOTktODkwRS00N0ZBLTkwN0YtOEYzRjg5Q0YyNzM0PC90aXRsZT48ZyBpZD0iRGVza3RvcCIgY2xhc3M9InN0MCI+PGcgaWQ9Il94MzFfNjgwX3g1Rl9TVF94NUZfUHJvZHVrdF94NUZfQ0FTX3g1Rl9WM194NDBfMngiIHRyYW5zZm9ybT0idHJhbnNsYXRlKC0xNTI5IC01MTg5KSI+PGcgaWQ9InRvcC1saW5rIiB0cmFuc2Zvcm09InRyYW5zbGF0ZSgxNTI5IDUxODkpIj48cGF0aCBpZD0iQ29tYmluZWQtU2hhcGUiIGNsYXNzPSJzdDEiIGQ9Ik0yNS41IDUxQzM5LjYgNTEgNTEgMzkuNiA1MSAyNS41UzM5LjYgMCAyNS41IDAgMCAxMS40IDAgMjUuNSAxMS40IDUxIDI1LjUgNTF6Ii8+PC9nPjwvZz48L2c+PHBhdGggY2xhc3M9InN0MiIgZD0iTTI2LjIgMjMuMWgzLjdjLjIgMCAuMy0uMS4zLS4yIDAgMCAwLS4xLS4xLS4xbC00LjMtNi4zYy0uMS0uMi0uMy0uMi0uNSAwTDIxIDIyLjhjLS4xLjIgMCAuNC4yLjRIMjV2MTIuNmgxLjFWMjMuMXoiLz48L3N2Zz4=);
    background-repeat: no-repeat;
    content: '';
    display: inline-block;
    background-size: 100% 100%;
    width: 51px;
    height: 51px;
  }
}
