.sidebar-event-application-wrapper {
  display: block;
  overflow: hidden;
  border: 1px solid $gray-400;
  padding: 0;
  @include margin($top: 'none', $right: 'none', $bottom: 'lg', $left: 'none');
  background-color: map-get($colors, 'white');
  cursor: pointer;

  @media (max-width: $largest-tablet-screen) {
    width: 100%;
  }

  &.open {
    .sidebar-event-application-content {
      grid-template-rows: 1fr;
    }

    .sidebar-event-application-header {
      h4 {
        &::after {
          transform: rotate(0deg);
        }
      }
    }
  }
}

button.sidebar-event-application-header {
  display: flex;
  width: 100%;
  cursor: pointer;
  border: none;
  background-color: white;
  padding: 10px 0 10px 0;
  margin: 0;
  border-bottom: 1px solid $gray-400;

  &:hover {
    background-color: map-get($colors, 'gray200');
  }

  &[aria-expanded='false'] {
    border: none;
  }

  h4 {
    color: $gray-800;
    @include padding($top: 'xs', $right: 'md', $bottom: 'xs', $left: 'lg');
    display: flex;
    align-items: center;
    justify-content: space-between;
    width: 100%;
    transition: background-color 0.3s ease-in-out;

    &::after {
      display: flex;
      content: '';
      right: 20px;
      min-width: 12px;
      align-items: center;
      justify-content: center;
      @include backgroundIcon('xcross');
      background-repeat: no-repeat;
      background-size: auto;
      opacity: 0.7;
      transform: rotate(45deg);
      transition: transform 0.25s ease-in-out;
      @include margin($top: 'none', $right: 'xs', $bottom: 'none', $left: 'sm');
    }
  }
}

.sidebar-event-application-content {
  display: grid;
  grid-template-rows: 0fr;
  transition: 500ms grid-template-rows ease;
  background-color: map-get($colors, 'white');

  > div {
    overflow: hidden;
  }

  &.open {
    display: 'grid';
    gridtemplaterows: 1fr;
    transition: 'grid-template-rows 0.1s linear';
  }

  p.infotext {
    @extend %text-color-gray800;
    @extend %text-size-xsm;
    @extend %lh-lg;
    @include padding($top: 'md', $right: 'lg', $bottom: 'sm', $left: 'lg');
    margin: 0;
  }
}
