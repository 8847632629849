.module-view {
  .module-details {
    margin-top: 2rem; /* Space below title/description */
    border-top: 1px solid #e0e0e0; /* Top border like screenshot 2 */
  }
  
  .module-field-row {
    display: flex; /* Use flexbox for label/value alignment */
    padding: 1rem 0; /* Vertical padding for each row */
    border-bottom: 1px solid #e0e0e0; /* Separator line for each row */
    align-items: baseline; /* Align label and start of value text */
  }
  
  .module-field-label {
    flex: 0 0 25%;
    padding-right: 1.5rem; /* Space between label and value */
    font-weight: bold; /* Make labels bold */
    color: #333;
    /* Remove default dt styles if needed */
    margin: 0;
    line-height: 1.4;
  }
  
  .module-field-value {
    flex: 1; /* Allow value to take remaining space */
    /* Remove default dd styles if needed */
    margin: 0;
    line-height: 1.4;
    color: #555;
  }
  
  /* Styling for HTML content within the value field */
  .module-field-value.html-content ul,
  .module-field-value.html-content ol {
    margin-top: 0.5em;
    margin-bottom: 0.5em;
    padding-left: 1.5em; /* Standard list indentation */
  }
  
  .module-field-value.html-content p {
    margin-top: 0.5em;
    margin-bottom: 0.5em;
  }
  .module-field-value.html-content p:first-child {
    margin-top: 0;
  }
  .module-field-value.html-content p:last-child {
    margin-bottom: 0;
  }
  
  /* Adjust title styling if needed (Volto usually has defaults) */
  .module-view .documentFirstHeading {
    margin-bottom: 1rem;
    /* Add any specific title styles here */
  }
  
  /* Optional: Responsive adjustments */
  @media (max-width: 767px) {
    .module-field-row {
      flex-direction: column; /* Stack label and value on small screens */
      align-items: stretch; /* Stretch items */
    }
  
    .module-field-label {
      flex-basis: auto; /* Reset flex-basis */
      padding-right: 0;
      margin-bottom: 0.5rem; /* Space between label and value when stacked */
      width: 100%;
    }
  
    .module-field-value {
      width: 100%;
    }
  }
}