.image-gallery .image-gallery-content {
  .image-gallery-left-nav,
  .image-gallery-right-nav {
    width: 70px;
    height: 70px;
    padding: unset;
    background: $black;
    opacity: 0.7;
  }

  .image-gallery-left-nav {
    .icon {
      transform: rotate(180deg);
    }
  }

  .image-gallery-thumbnails {
    margin-top: 50px;
  }
  .image-wrapper {
    position: relative;
    width: 100%;

    figcaption {
      position: relative;
      margin: 0px;

      .title {
        margin-top: 20px;
      }
    }

    .image-number-container {
      position: absolute;
      top: -39px;
      right: 10px;
      padding: 5px 12px;
      background: map-get($colors, 'primary');
      color: map-get($colors, 'white');
      opacity: 0.7;
    }
  }

  .image-gallery-icon:hover {
    color: $white;
    opacity: 1;
  }
  .image-gallery-slide {
    .image-gallery-image {
      max-height: unset;

      img {
        width: 100%;
        height: 100%;
        object-fit: contain;
      }
    }
  }
}
