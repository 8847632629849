@import '../variables';

%alert-default-values {
  display: flex;
  flex-direction: row;
  align-items: center;
  justify-content: space-between;
  @extend %text-size-xxs;
  @extend %lh-md;
  @extend %text-color-gray700;
  @extend %m-sm;
  @extend %p-sm;
}

.default-alert,
.alert-info {
  @extend %alert-default-values;
  border: 2px solid $gray-400;
  background-color: $white;
}

.alert-success {
  @extend %alert-default-values;
  border: 2px solid $green;
  background-color: #d8f7df;
}

.alert-error {
  @extend %alert-default-values;
  border: 2px solid $red;
  background-color: #fcedf0;
}

.alert-warning {
  @extend %alert-default-values;
  border: 2px solid $orange;
  background-color: #f8f0c8;
}

.alert-close-btn {
  @extend %text-color-gray700;
  cursor: pointer;
  font-size: $font-size-22;
  font-weight: bold;
  transition: 0.3s;
}

.alert-close-btn:hover {
  color: $black;
}
