@import '../variables';

.testimonial {
  .testimonial.has_bg {
    max-width: unset !important;
    @include margin($top: 'lg', $right: 'none', $bottom: 'lg', $left: 'none');
    background: url('../../components/Blocks/Testimonial/blockquote_bg.png');
  }

  figure.testimonial {
    @include margin($top: 'lg', $right: 'none', $bottom: 'lg', $left: 'none');
    background: none;
    // Reset figure default margins
    margin-block-start: 0;
    margin-block-end: 0;
    margin-inline-start: 0;
    margin-inline-end: 0;
  }

  .testimonial_content {
    display: grid;
    grid-template-columns: 1fr 1fr;
    grid-gap: map-get($sizes, 'lg');
    align-items: start;

    .author_image {
      max-width: max-content;
    }
  }

  .has_bg .testimonial_content {
    display: grid;
    grid-template-columns: 1fr;
  }

  .has_bg .testimonial_text_block {
    @include margin($top: 'xl', $right: 'md', $bottom: 'lg', $left: 'md');

    @media screen and (min-width: $largest-tablet-screen) {
      margin-left: 0;
    }
  }

  // Ensure proper spacing for blockquote
  blockquote {
    margin-block-start: 0;
    margin-block-end: map-get($sizes, 'md');
    margin-inline-start: 0;
    margin-inline-end: 0;
  }
  .testimonial_text_block {
    blockquote {
      margin-top: 0;
      margin-bottom: map-get($sizes, 'md');
      font-family: Spectral, serif;
      font-size: calculateRem($font-size-20);
      font-style: italic;
      font-weight: 300;
      line-height: 2;
    }

    figcaption {
      cite.author_name {
        display: block;
        color: #767573;
        font-size: 0.9rem;
        font-weight: $font-weight-400;
        font-style: normal;
        @include margin(
          $top: 'sm',
          $right: 'none',
          $bottom: 'md',
          $left: 'none'
        );
      }
    }
  }

  @media (max-width: $largest-mobile-screen) {
    .testimonial_content {
      grid-template-columns: 1fr;
    }

    .testimonial_text_block {
      figcaption cite.author_name {
        @include margin(
          $top: 'none',
          $right: 'none',
          $bottom: 'md',
          $left: 'none'
        );
        width: 100%;
        height: 100%;
      }
    }
  }

  .testimonial.no_button {
    @include margin($top: 'lg', $right: 'none', $bottom: 'xl', $left: 'none');
  }

  .profile_button {
    @include margin($top: 'lg', $right: 'none', $bottom: 'xl', $left: 'none');
    display: flex;
  }

  @media (min-width: $largest-tablet-screen) {
    .testimonial.has_bg {
      padding-left: 100px;
      margin-left: -100px !important;
      padding-right: 100%;
      margin-right: -100% !important;
    }
  }
}

#page-document blockquote {
  padding: unset;
}
