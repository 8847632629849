#main .global-search-container {
  position: absolute;
  top: 0;
  right: 0;
  z-index: 999;

  .overlay {
    position: fixed;
    content: ' ';
    height: 100vh;
    width: 100vw;
    top: 0;
    left: 0;
    opacity: 0.3;
    background-color: rgba(124, 121, 87);
  }

  .global-search-icon-container {
    position: absolute;
    top: 0;
    right: 0;
    width: 70px;
    height: 70px;

    button {
      width: 100%;
      height: 100%;
      border: none;
      cursor: pointer;
      background: $white;
      display: flex;
      align-items: center;
      justify-content: center;

      &:hover {
        background: $gray-200;
      }
    }

    @media screen and (max-width: $largest-tablet-screen) {
      display: none;
    }
  }

  .global-search-content-container {
    position: fixed;
    top: 0;
    right: 0;
    width: 800px;
    height: 100vh;
    background: $white;
    transform: translateX(100%);
    visibility: hidden;
    opacity: 0;
    transition: all 0.5s ease-in-out;

    @media screen and (max-width: $largest-tablet-screen) {
      width: 100%;
    }

    .global-searchbar-panel {
      height: 100%;
    }

    .global-search-result-container {
      padding: 0 25px 50px 30px;
      overflow-y: auto;
      height: calc(100vh - 110px);

      .noresult-container {
        margin-bottom: 20px;
        margin-top: 30px;
        p {
          margin-bottom: 0px;
        }
        span {
          font-size: 13px;
          font-weight: 400;
        }
      }
    }

    &.is-open {
      overflow-y: auto;
      webkit-overflow-scrolling: touch;
      position: static;
      scrollbar-width: none;
      ms-overflow-style: none;
      visibility: visible;
      opacity: 1;
      transform: translateX(0) !important;
    }

    .searchbar-container {
      position: relative;

      form {
        display: flex;
        position: relative;

        input {
          width: 100%;
          height: 70px;
          padding-left: 30px;
          padding-right: 100px;
          border: none;
          background-color: $gray-100;
          box-shadow: inset 0 -3px 3px 0 rgba(0, 0, 0, 0.04);
          font-size: 18px;
          line-height: 70px;
          &:focus-visible {
            outline: none;
          }
        }

        button {
          position: absolute;
          display: flex;
          align-items: center;
          justify-content: center;
          right: 0;
          border: 2px solid transparent;
          border-radius: 0;
          color: $black;
          cursor: pointer;
          background: transparent;
          width: 70px;
          height: 100%;

          &:hover {
            background: $gray-200;
          }
        }
      }
    }

    .search-bar-intro {
      padding: 0 30px 20px;
      color: $black;

      p {
        margin: 48px 0px;
        font-size: 18px;
        line-height: 1.5;
        color: $black;
      }

      a {
        display: flex;
        padding: 10.5px 17px;
        border: 1px solid $gray-400;
        margin-bottom: 15px;
        cursor: pointer;
        font-size: 15px;
        text-decoration: none;
        font-weight: 500;
        color: $black;

        &:hover {
          border-color: $black;
        }

        .small-arrow-icon {
          align-self: center;
          margin-left: auto;
        }
      }
    }

    .search-tab-categories {
      .ui.menu.searchTabs {
        flex-wrap: wrap;
        gap: 5px;
        margin: 20px 0px;
        border-bottom: none;

        .searchTab.item {
          color: $black;
          background: $white;
          border: 2px solid $gray-200;
          cursor: pointer;
          font-size: 15px;
          line-height: 22.5px;
          height: 45px;
          font-weight: 500;
          padding: 0 10px;

          .searchCounter {
            margin-left: 4px;
          }
          .searchCounter::before {
            content: '(';
          }
          .searchCounter::after {
            content: ')';
          }

          &.active {
            background: $yellow;
          }

          &.noresults {
            color: $gray-700;
            opacity: 0.4;
          }

          &:hover {
            background: $black;
            color: $white;
          }

          .searchCounter {
            vertical-align: baseline;
          }
        }
      }
    }

    .suggestions-container {
      list-style: none;
      margin: 0px;
      padding: 0px;

      li > div {
        cursor: pointer;
        border-bottom: 1px solid $gray-400;
        display: flex;
        align-items: center;
        padding-top: 10px;
        padding-bottom: 10px;
        gap: 5px;

        &:hover {
          background: rgba(241, 241, 238, 0.5);
        }
      }
    }

    .searchbar-corrected-title {
      .searchbar-corrected-title-line1 {
        font-size: 1.125rem;
      }
      .searchbar-corrected-title-line2 {
        font-size: 0.85rem;
      }
      div {
        color: #000;
      }
    }

    .searchbar-title {
      p {
        border-bottom: 1px solid $gray-400;
        margin-top: 20px;
        padding-bottom: 10px;
        font-size: 12px;
        font-weight: 600;
        color: #777;
        text-transform: uppercase;
      }
    }

    .more-result-container {
      height: 100px;
      align-items: center;
      justify-content: center;

      .more-container {
        flex-grow: 1;
        display: flex;
        height: 100%;

        &:hover {
          background: $gray-200;
        }

        button {
          display: inline-block;
          flex-grow: 1;
          text-decoration: none;
        }

        span {
          line-height: 100px;
          padding-right: 12px;
        }
      }
    }
  }

  .result-item {
    padding: 10px;
    position: relative;
    border-bottom: 1px solid rgba(0, 0, 0, 0.1);

    &:hover {
      background-color: $gray-200;
    }

    .result-top {
      display: flex;
      justify-content: space-between;
      align-items: center;

      .breadcrumb,
      .breadcrumbs .breadcrumb,
      .breadcrumbs .breadcrumb .section {
        font-size: 13px;
        font-weight: 400;
        color: #767573;
        .section {
          margin: 0;
          padding: 0;
        }
        .divider {
          margin: 0 4px 0 7px;
        }
      }

      .result-type {
        span {
          padding: 0 12px;
          background: #fff;
          font-size: 12px;
          border: 2px solid $gray-200;
          line-height: 1.5;
        }
      }
    }
    a {
      margin-bottom: 0px;
      text-decoration: none;
    }
    .tileHeadline {
      margin: 0px;
      font-size: 18px;
      padding-top: 6px;
      padding-bottom: 8px;
      color: #000;
      font-weight: 400;

      a {
        margin-bottom: 0px;
        text-decoration: none;
      }
      .title em {
        font-style: normal;
        font-weight: 700;
      }
    }

    .tileBody {
      padding-bottom: 10px;

      .itemDescription {
        margin-bottom: 0px;
        display: -webkit-box;
        overflow: hidden;
        -webkit-box-orient: vertical;
        line-clamp: 2;
        -webkit-line-clamp: 2;
        text-overflow: ellipsis;
        font-size: 14px;
        font-weight: 400;

        em {
          font-style: normal;
          font-weight: 700;
          color: #000;
        }
      }

      .additional-desc {
        padding-top: 10px;
        margin-top: 0px;
        font-size: 16px;
        font-weight: 300;
        line-height: 18px;
        color: #666;
      }
    }
  }
}

#main .global-search-breadcrumbs {
  .breadcrumbs {
    padding-left: 0px;
    padding-right: 0px;
    padding-top: 0px;

    .dropdown-menu {
      display: flex;
      gap: 5px;
    }

    a {
      &:first-child {
        margin-left: 0px;
      }
    }
  }
}

body.contenttype-lrf #main .global-search-breadcrumbs {
  .breadcrumbs {
    display: block;
  }
}

.mobile.global-search-container {
  position: absolute;
  width: 100%;
  top: 0px;
  z-index: -1;
  height: initial;

  .default-result-item .result-top {
    flex-flow: column-reverse;
    align-items: flex-start;

    .result-type {
      align-self: flex-end;
    }
  }
  .mobile.global-search-content-container {
    position: absolute;
    z-index: -5;
    width: 100%;
    top: 70px;
    transform: translateY(-100px);
    transition: all 0.25s ease-in-out;
    visibility: hidden;
    opacity: 0;

    .global-search-result-container {
      height: calc(100vh - 170px);
      position: relative;
      overflow-y: auto;
    }

    &.is-open {
      transform: translateY(0) !important;
      visibility: visible;
      opacity: 1;
    }
  }

  .search-tab-categories {
    padding-bottom: 1px;
    .ui.menu.searchTabs {
      overflow-x: scroll;
      white-space: nowrap;
      margin-top: 0px;
      margin-bottom: 0px;
      padding-top: 20px;
      padding-bottom: 20px;

      @media screen and (max-width: $largest-mobile-screen) {
        flex-wrap: nowrap;
      }

      &.show-after::after {
        position: absolute;
        right: 0;
        content: '';
        width: 100px;
        height: 55px;
        background: linear-gradient(
          to left,
          #fff 25%,
          rgba(255, 255, 255, 0) 100%
        );
        visibility: visible;
      }
      &.show-before::before {
        position: absolute;
        left: 30px;
        content: '';
        width: 70px;
        height: 55px;
        background: linear-gradient(
          to right,
          #fff 0,
          rgba(255, 255, 255, 0) 100%
        );
        visibility: visible;
        z-index: 1;
      }

      .item {
        flex: unset;
      }

      &::-webkit-scrollbar {
        display: none;
      }
    }
  }
}

.is-authenticated {
  .mobile.global-search-container {
    .mobile.global-search-content-container {
      .global-search-result-container {
        height: calc(100vh - 270px);
        position: relative;
        overflow-y: auto;
      }
    }
  }
}
