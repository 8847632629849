@import '../variables';

.web25__eventdetail {
  display: flex;
  flex-direction: column;
  align-items: normal;
  justify-content: flex-start;
  background-color: map-get($colors, 'gray200');
  z-index: -1;

  @include padding($top: 'md', $right: 'md', $bottom: 'lg', $left: 'md');
  @include margin($top: 'xl', $right: 'none', $bottom: 'xs', $left: 'none');

  @media screen and (max-width: $largest-mobile-screen) {
    padding-left: 0;
    padding-right: 0;
  }

  ol {
    counter-reset: numList;
    list-style-position: inside;
    list-style-type: none;

    li {
      &.field_names {
        padding-left: 0;
      }

      h3 {
        @extend %text-color-primary;
        @extend %mb-xs;

        @media screen and (max-width: $largest-mobile-screen) {
          font-size: $font-size-18;
        }
      }
    }
  }

  .field_location {
    line-height: $line-height-7;
    white-space: pre-wrap;
  }

  .field_data {
    display: flex;
    justify-content: flex-start;
    flex-direction: row;
    align-items: center;

    color: map-get($colors, 'gray800');
    @extend %text-weight-regular;
    @extend %mt-none;
    @extend %mb-lg;
  }

  .entity {
    @include margin($top: 'none', $right: 'xs', $bottom: 'none', $left: 'none');
  }

  .field_names {
    @extend %text-color-primary;
    @extend %text-size-smd;
    @extend %text-weight-semibold;
    line-height: $line-height-1;
  }
}
