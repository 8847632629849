.format-widget .format-buttons {
  display: flex !important;
  align-items: center;
  .active-format-button {
    border: 1px solid #2996da;
    background: transparent !important;
  }
  .format-button {
    padding: 4px;
    border-radius: 1px;
    margin-left: 0px;

    &:hover {
      background-color: #edf1f2 !important;
    }
  }
}
.sidebar-container button {
  padding: 4px;
}
