// Pre-calculate map-get values
$size-md: map-get($sizes, 'md');
$size-sm: map-get($sizes, 'sm');
$size-xs: map-get($sizes, 'xs');
$color-white: map-get($colors, 'white');
$color-gray400: map-get($colors, 'gray400');
$color-success: map-get($colors, 'success');
$color-error: map-get($colors, 'error');

.application-accordion {
  position: relative;
  box-sizing: border-box;
}

.accordion {
  max-width: 100%;
  padding: 0;
  background-color: map-get($colors, 'gray200');

  &.open {
    .accordion-body {
      grid-template-rows: 1fr;
    }

    .accordion-header {
      background-color: $yellow-3;

      &::after {
        transform: rotate(0deg);
      }
    }
  }

  &-header {
    display: flex;
    min-height: 60px;
    align-items: center;
    justify-content: space-between;
    border-bottom: 2px solid $color-white;
    transition: background-color 0.3s ease-in-out;
    cursor: pointer;

    @include padding($vertical: 'sm', $horizontal: 'sm');

    &:hover {
      background-color: $color-gray400;
    }

    button,
    a {
      flex: 3;
      text-align: center;

      @media screen and (max-width: $largest-tablet-screen) {
        display: none;
      }
    }

    &::after {
      display: flex;
      justify-content: center;
      align-items: center;
      right: 20px;

      min-width: 12px;
      @include backgroundIcon('xcross');
      background-repeat: no-repeat;
      background-size: auto;
      content: '';
      opacity: 0.7;
      transform: rotate(45deg);
      transition: transform 0.25s ease-in-out;
      @include margin($top: 'none', $right: 'xs', $bottom: 'none', $left: 'sm');
    }

    &-status-location {
      display: flex;
      flex: 10;
      flex-direction: row;
      align-items: inherit;

      @media screen and (max-width: $largest-mobile-screen) {
        flex-direction: column;
        align-items: unset;
      }
    }
  }

  &-status {
    &.open,
    &.closed,
    &.no-status {
      display: block;
      width: $size-sm;
      min-width: $size-sm;
      height: $size-sm;
      border-radius: 50%;
      text-indent: -20000px;

      @include margin(
        $top: 'none',
        $right: 'xs',
        $bottom: 'none',
        $left: 'none'
      );

      @media screen and (max-width: $largest-mobile-screen) {
        display: none;
      }
    }

    &.open {
      background: $color-success;
    }

    &.closed {
      background: $color-error;
    }
  }

  &-title {
    flex: 1 1 40%;
    @extend %text-size-sm;
    @extend %text-weight-bold;
    @extend %lh-md;
    padding-right: $size-xs;
  }

  &-place {
    display: flex;
    flex-direction: row;
    flex: 0 0 50%;
    padding-right: map-get($sizes, 'sm');
    @extend %text-size-sm;
    @extend %lh-md;

    @media screen and (max-width: $largest-mobile-screen) {
      padding-top: $size-sm;
    }

    .accordion-status {
      display: none;

      @media screen and (max-width: $largest-mobile-screen) {
        display: block;
        transform: translateY(2px);
      }
    }
  }

  &.publish_state_private {
    border-left: map-get($sizes, 'xxs') solid $red;
  }
}

.accordion-body {
  display: grid;
  background-color: $color-white;
  grid-template-rows: 0fr;
  padding-inline: 0;
  transition: 500ms grid-template-rows ease;

  > div {
    overflow: hidden;

    p {
      &.no_infos {
        @include padding(
          $top: 'none',
          $right: 'none',
          $bottom: 'none',
          $left: 'xxs'
        );
        @include margin(
          $top: 'none',
          $right: 'none',
          $bottom: 'xs',
          $left: 'lg'
        );
      }
    }
  }

  dl {
    @include margin($top: 'lg', $right: 'sm', $bottom: 'md', $left: 'sm');

    div {
      display: flex;
      flex-direction: row;
      border-bottom: 1px solid $color-gray400;

      &:last-child {
        border-bottom: unset;
      }

      dt,
      dd {
        display: flex;
        @include padding($vertical: 'md', $horizontal: 'none');
        margin: 0;
      }

      dt {
        align-items: center;
        @extend %text-size-sm;
        @extend %text-weight-semibold;

        @media screen and (max-width: $largest-mobile-screen) {
          @include padding($vertical: 'xs', $horizontal: 'none');
        }
      }

      dd {
        @extend %text-size-sm;
        justify-content: flex-end;
        align-items: center;
        flex: 5;

        @media screen and (max-width: $largest-mobile-screen) {
          @include padding(
            $top: 'none',
            $right: 'none',
            $bottom: 'xs',
            $left: 'none'
          );
          justify-content: flex-start;
        }
      }

      @media screen and (max-width: $largest-mobile-screen) {
        flex-direction: column;
      }
    }

    p {
      @media screen and (min-width: $large-desktop-screen) {
        padding-top: $size-md;
      }
    }
  }

  &-signup {
    border-bottom: unset !important;

    a {
      @include margin($vertical: 'sm', $horizontal: 'none');
    }

    @media screen and (min-width: $largest-tablet-screen) {
      &:not(:has(> .accordion-body-signup)) {
        display: none;
      }
    }
  }
}

.loading {
  display: flex;
  align-items: center;
  justify-content: center;
}

#page-edit {
  .applicationAccordion {
    .blocks-group-wrapper.transparent {
      padding-top: 0;
      padding-bottom: 0;
    }
  }
}
