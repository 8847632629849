.anchor-container {
  display: flex;
  justify-content: space-between;

  .id-container {
    display: flex;
    align-items: center;
    span {
      margin-left: 10px;
    }
  }
}

.copy-link-url {
  margin-top: 16px;
  color: #007eb1;
  cursor: pointer;
  line-height: 32px;
}

.sidebar-container #sidebar-properties .note {
  padding: 0.5rem;
  color: #878f93;
  font-size: 0.72222222rem;
  font-weight: 300;
}
