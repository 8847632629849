.web25__fullbleed-teaser {
  display: grid;
  grid-template-rows: auto 1fr;
  grid-template-columns: fit-content(100%);
  grid-template-areas:
    'fullbleed-teaser-image'
    'fullbleed-teaser-wrapper';
  background-color: map-get($colors, 'gray200');

  padding: map-get($sizes, 'md');
  margin: map-get($sizes, 'xl') 0;

  @media screen and (min-width: $largest-mobile-screen) and (max-width: $largest-tablet-screen) {
    grid-template-columns: 100%;
    grid-template-rows: 1fr;
    height: 400px;

    padding: 0;
    background-color: unset;
  }

  @media screen and (min-width: $smallest-desktop-screen) {
    grid-template-columns: 100%;
    grid-template-rows: 1fr;
    height: $fullBleedTeaserHeight;

    padding: 0;
    background-color: unset;
  }

  @media screen and (max-width: $largest-mobile-screen) {
    position: relative;
    left: -15px;
    width: calc(100% + 30px);
  }

  .fullbleed-teaser-image {
    width: 100%;

    @media screen and (min-width: $largest-mobile-screen) and (max-width: $largest-tablet-screen) {
      position: absolute;
      display: flex;
      align-items: center;
      left: 0;
      height: 400px;
      min-height: 400px;
      max-height: 400px;
      overflow: hidden;

      img.responsive {
        position: absolute;
        left: 50%;
        transform: translateX(-50%);
        width: auto;
        height: 400px;
        max-height: 400px;
        max-width: unset;
      }
    }

    @media screen and (min-width: $smallest-desktop-screen) {
      position: absolute;
      display: flex;
      align-items: center;
      left: 0;
      width: 100%;
      height: $fullBleedTeaserHeight;
      min-height: $fullBleedTeaserHeight;
      max-height: $fullBleedTeaserHeight;
      overflow: hidden;

      img.responsive {
        position: relative;
        width: 100%;
        min-height: $fullBleedTeaserHeight;
        object-fit: cover;
        object-position: center;
      }
    }

    @media screen and (max-width: $largest-mobile-screen) {
      width: 100%;
      height: auto;
      min-height: unset;

      img.responsive {
        width: 100%;
        height: auto;
      }
    }

    .infobox-image {
      width: auto;
      min-width: auto;
      display: flex;
      justify-content: center;
      align-items: center;

      @media screen and (min-width: $largest-mobile-screen) and (max-width: $largest-tablet-screen) {
        height: 400px;
        max-height: 400px;
      }

      @media screen and (min-width: $smallest-desktop-screen) {
        height: $fullBleedTeaserHeight;
        max-height: $fullBleedTeaserHeight;
        width: 100%;
      }

      @media screen and (max-width: $largest-mobile-screen) {
        height: unset;
      }
    }
  }

  .fullbleed-teaser-wrapper {
    margin-top: map-get($sizes, 'md');

    &::after {
      margin-top: map-get($sizes, 'md');
      @include backgroundIcon('arrow');
      @include animate(slide-out-arrow);
    }

    &:hover {
      background-color: map-get($colors, 'gray200');

      &::after {
        @include animate(slide-in-arrow);
      }
    }

    h3 {
      &::after {
        content: unset;
      }
    }

    @media screen and (min-width: $largest-mobile-screen) {
      position: relative;
      background: map-get($colors, 'white');
      display: grid;
      grid-template-rows: min-content;
      align-self: center;

      width: 50%;
      max-width: 460px;
      padding: map-get($sizes, 'lg');
    }
  }
}

// CMS EDIT VIEW
#page-edit {
  .fullbleed-teaser-image {
    position: absolute;
    width: 100%;
    display: flex;
    align-items: center;
    overflow: hidden;

    @media screen and (min-width: $largest-mobile-screen) and (max-width: $largest-tablet-screen) {
      height: 400px;
      max-height: 400px;
    }

    @media screen and (min-width: $smallest-desktop-screen) {
      height: $fullBleedTeaserHeight;
      max-height: $fullBleedTeaserHeight;
    }

    img {
      &:hover {
        scale: unset !important;
      }

      @media screen and (min-width: $largest-mobile-screen) and (max-width: $largest-tablet-screen) {
        position: absolute;
        left: 50%;
        transform: translateX(-50%);
        width: auto;
        height: 400px;
        max-height: 400px;
      }

      @media screen and (min-width: $smallest-desktop-screen) {
        position: relative;
        width: 100%;
        max-height: $fullBleedTeaserHeight;
        object-fit: cover;
        object-position: center;
      }
    }
  }
}
