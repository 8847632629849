@import '../variables';

.react-aria-Label,
.react-aria-RadioGroup > .react-radio-label-wrapper > label,
.react-select-container > .react-select-label-wrapper > label {
  @extend %text-color-gray700;
  @extend %text-size-sm;
  @extend %text-weight-semibold;

  &::after {
    /* Marks after Label (Required, etc) */
    display: inline-block;
    margin-left: 3px;
    color: $gray-800;
    line-height: 0;
    vertical-align: middle;
  }
}
