@import '../_variables.scss';

.table-of-contents .ui.list > .item_toc a {
  color: $gray-700 !important;
  font-weight: $font-weight-400;
  text-decoration: underline !important;
}
.table-of-contents .ui.list > .item_toc a:hover {
  color: black !important;
}
.table-of-contents ol.ui.list li:before {
  color: $gray-900;
}
.table-of-contents ul.ui.bulleted.list li::before {
  color: $gray-800 !important;
}

.table-of-contents.non_enumeration ol.ui.list li:before {
  display: none;
}
.table-of-contents .ui.list > .headline-5 a {
  margin-left: 10px;
}

.table-of-contents .ui.list > .headline-6 a {
  margin-left: 20px;
}
