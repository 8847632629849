.block.image.align.center figure {
  max-width: max-content !important;
}
.block.image .figure a {
  cursor: pointer;
}
.block.image {
  figure {
    &.large_format,
    .centre,
    .left,
    .right {
      width: 100% !important;
      margin: 0px;
    }

    &.half_format {
      width: 50%;

      @media only screen and (max-width: $largest-mobile-screen) {
        width: 50%;
      }
    }

    &.third_format {
      width: 33%;

      @media only screen and (max-width: $largest-mobile-screen) {
        width: 100%;
      }
    }
    &.portrait_format {
      width: 20%;

      @media only screen and (max-width: $largest-mobile-screen) {
        width: 40%;
      }
    }
    &.right {
      margin-bottom: 0px;
      margin-left: 10px;

      img {
        margin-right: 0px !important;
        margin-bottom: 0px;
        margin-left: 0px !important;
        float: right;
      }
      .description {
        p,
        a {
          float: left;
          @include marginal-description();
        }
      }
    }

    &.left {
      margin-right: 20px;
      margin-bottom: 0px;

      // @container (max-width: #{$largest-mobile-screen}) {
      //   margin-bottom: $spacing-large;
      // }

      img {
        margin-right: 20px !important;
        margin-bottom: 0px;
        float: left;
      }
      .description {
        p,
        a {
          float: right;
          @include marginal-description();
        }
      }
    }

    &.third_format {
      &.center {
        width: 33% !important;
        margin-bottom: 0px;
        margin-left: 0px !important;
        @media only screen and (max-width: $largest-mobile-screen) {
          width: 100% !important;
        }
      }
    }
    &.half_format {
      &.center {
        width: 50% !important;
        margin-bottom: 0px;
      }
      @media only screen and (max-width: $largest-mobile-screen) {
        width: 50% !important;
      }
    }
    &.portrait_format {
      &.center {
        width: 20% !important;
        margin-bottom: 0px;
        margin-left: 0px !important;
        @media only screen and (max-width: $largest-mobile-screen) {
          width: 40% !important;
        }
      }
    }
  }

  h2.title {
    padding-bottom: 8px;
    border-color: transparent;
  }
}

figcaption {
  margin: 0;
  font-size: 14px;
  font-weight: 300;
  .title {
    color: $grey;
    text-align: left;
  }
}
.description {
  color: $grey;
  font-size: 16px;
  font-weight: 300;
  line-height: 18px;
  text-align: left;
}

// OVERWRITES

#page-document .block.gridBlock .title {
  font-size: calculateRem($font-size-16) !important;
}
