@import '../variables';

.scroll_gif {
  @media only screen and (min-width: $largest-mobile-screen) {
    display: none;
  }
  @media only screen and (max-width: $largest-mobile-screen) {
    position: absolute;
    top: 0;
    right: 0;
    bottom: 0;
    left: 0;
    margin: auto;
  }
}
.scroll_gif_container {
  @media only screen and (max-width: $largest-mobile-screen) {
    position: relative;
    overflow: auto;
    flex-flow: row;
    flex-wrap: nowrap;
  }
}

//for now remove the grey color picker
// .color-picker-widget .wrapper .ui.circular.button.grey {
//   display: none;
// }

.ui[class*='very basic'].table.slate-table-block:not(.sortable):not(.striped)
  thead
  tr:first-child
  th {
  padding-top: 1em;
}

.ui[class*='very basic'].table.slate-table-block:not(.sortable):not(.striped)
  th:first-child,
.ui[class*='very basic'].table.slate-table-block:not(.sortable):not(.striped)
  td:first-child {
  padding-left: 1em;
}

.ui.table.slate-table-block thead tr:first-child th p {
  color: black;
  font-size: $font-size-18;
  font-weight: $font-weight-600;
  text-transform: none;
}
.ui.table.slate-table-block tbody tr td p {
  margin-top: 0px;
  margin-bottom: 0px;
}
.container-table-block {
  max-width: var(--narrow-container-width);
  @media only screen and (max-width: $largest-mobile-screen) {
    width: 500px;
    height: max-content;
  }
}
.ui.table.slate-table-block {
  @media only screen and (max-width: $largest-mobile-screen) {
    width: 100%;
    height: 100%;
    overflow-x: auto;
    display: block;
    white-space: nowrap;
  }
}
.ui.basic.table.slate-table-block th {
  border-top: 1px solid $gray-300;
  border-bottom: 3px solid $gray-300;
}
.ui.sortable.table.slate-table-block thead th {
  border-left: none;
}

.ui.fixed.table.slate-table-block {
  table-layout: auto;
  @media only screen and (max-width: $largest-mobile-screen) {
    table-layout: fixed;
  }
}
