@import '../variables';
@import '../globals/iconPosition';
@import '../globals/animations';

/**
 * Base styles for `.btn` class and `<button>` elements.
 * It sets up the border, cursor, and outline properties,
 * includes the font size mixin, and styles the disabled state.
 */
.btn {
  position: relative;
  display: flex;
  flex-direction: row;
  align-items: center;
  justify-content: center;

  cursor: pointer;

  font-family: 'Inter', sans-serif;
  font-weight: $font-weight-500;
  line-height: $line-height-7;
  outline: none;
  text-decoration: none;

  @include font-size($font-size-15);

  &:focus,
  &:active {
    outline: 1px solid $yellow-3;
  }

  &:out-of-range {
    outline: none;
  }

  &:disabled {
    background-color: $black;
    color: $white;
    cursor: not-allowed;
    opacity: 0.4;

    &:hover {
      background-color: $black;
      color: $white;
      opacity: 0.4;
    }
  }
}

/**
 * Mixin for generating button styles.
 *
 * @param {Color} $color - The color of the button text. Default is null.
 * @param {Color} $bg-color - The background color of the button. Default is null.
 * @param {Color} $hover-color - The color of the button text on hover. Default is null.
 * @param {Color} $bg-hover - The background color of the button on hover. Default is null.
 * @param {Color} $border-color - The color of the button border. Default is transparent.
 * @param {Color} $border-hover-color - The color of the button border. Default is transparent.
 * @param {String} $iconAlign - The direction of the arrow if it exists. Default is null.
 */
@mixin button-styles(
  $color: null,
  $bg-color: null,
  $hover-color: null,
  $bg-hover: null,
  $border-color: null,
  $border-hover-color: null
) {
  @extend .btn;
  border-color: $border-color;
  background-color: $bg-color;

  color: $color;

  @include padding($vertical: 'sm', $horizontal: 'sm');

  &:hover {
    border-color: $border-hover-color;
    background-color: $bg-hover;
    color: $hover-color;
    text-decoration: none;
  }
}

.btn {
  &-primary {
    @include button-styles(
      $white,
      $black,
      $black,
      $yellow,
      transparent,
      transparent
    );
  }

  &-secondary {
    @include button-styles($black, $yellow, $white, $black, transparent);
  }

  // SUBNAVIGATION
  &-lemon {
    @include button-styles($black, $lemon, $black, $yellow);
    @include padding($vertical: 'sm', $horizontal: 'lg');

    &--subnav {
      @extend .btn-lemon;

      justify-content: flex-start;
      color: $gray-800;
      gap: map-get($sizes, 'sm');
      @include padding($vertical: 'sm', $horizontal: 'sm');

      &::before {
        @include backgroundIcon('triangle');
        transform: rotate(180deg);
      }
    }
  }

  &-white {
    @include button-styles($black, $white, $black, $white, $black, $black);
    border: 2px solid $gray-400;

    // SEARCH LISTING FILTERS
    &--colored {
      @extend .btn-white;
      @include button-styles($black, $white, $white, $black, $gray-200, $black);
      @include padding($vertical: 'xs', $horizontal: 'xs');

      &[data-is-active='true'] {
        @include button-styles($black, $yellow, $white, $black, transparent);
        @include padding($vertical: 'xs', $horizontal: 'xs');
      }

      &:disabled {
        background-color: $white;
        color: inherit;
      }
    }

    // SEARCHBAR
    &--searchbar-nav {
      @extend .btn-white;
      justify-content: space-between;
      border: 1px solid $gray-400;

      font-size: $font-size-15;
      font-weight: $font-weight-500;

      @include button-styles($black, transparent, inherit, transparent);
      @include padding($vertical: 'xs', $horizontal: 'sm');

      &::after {
        @include backgroundIcon('arrow');
        background-position: 0 0;
      }
    }

    &--searchbar-link {
      @extend .btn-white;
      border: unset;
      font-weight: $font-weight-600;

      @include button-styles($gray-700, transparent, inherit, $gray-200);
      @include padding($vertical: 'lg', $horizontal: 'xl');
    }
  }

  &-gray {
    @include button-styles($gray-800, $gray-200, $black, $gray-400);
    @include padding($vertical: 'sm', $horizontal: 'lg');

    // SUBNAVIGATION
    &--subnav {
      @extend .btn-gray;
      @include padding($top: 'sm', $right: 'lg', $bottom: 'sm', $left: 'sm');
      justify-content: space-between;

      &::after {
        @include backgroundIcon('triangle');
        transform: rotate(0);
        position: absolute;
        right: 10px;
      }
    }
  }

  // SEARCHBAR
  &-searchbar-open {
    @include button-styles($black, transparent, $black, $gray-100);
    @include padding($vertical: 'xl', $horizontal: 'xl');
    justify-content: center;
    border: unset;

    &::before {
      @include backgroundIcon('magnifying-glass');
    }

    &[data-is-active='true'] {
      &::before {
        @include backgroundIcon('close');
      }
    }
  }

  // NAVIGATION LINKS
  &-nav {
    @include font-size($font-size-18);

    justify-content: space-between;
    border: unset;
    color: $black;
    font-weight: $font-weight-400;

    @include button-styles($black, var(--env-color), $black, $yellow-2);
    @include padding($top: 'sm', $right: 'md', $bottom: 'sm', $left: 'lg');

    &::after {
      @include backgroundIcon($iconName: 'triangle');
    }

    &:hover {
      &::after {
        @include animate(slide-in-left, $time: map-get($speed, 'fast'));
      }
    }

    &:not(:hover) {
      &::after {
        @include animate(slide-out-left, $time: map-get($speed, 'fast'));
      }
    }

    &[data-is-active='true'] {
      background-color: $yellow-1;

      &[data-is-expandable='true'] {
        &::after {
          @include animate(slide-in-left);
        }
      }
    }

    &[data-is-expandable='false'] {
      &::after {
        @include animate(slide-out-left);
      }
    }

    &--sublevel {
      @extend .btn-nav;

      border: unset;

      font-size: $font-size-14;
      font-weight: $font-weight-600;
      text-decoration: none;

      @include button-styles($gray-700, transparent, $black, $gray-100);
      @include padding($top: 'sm', $right: 'md', $bottom: 'sm', $left: 'lg');

      &:hover {
        color: $black !important;
      }

      &[data-is-active='true'] {
        @extend .btn-nav--sublevel;
        @include button-styles($black, $lemon);
        @include padding($top: 'sm', $right: 'md', $bottom: 'sm', $left: 'lg');

        &[data-is-expandable='false'] {
          @extend .btn-nav--sublevel;
          content: unset;
        }
      }
    }
  }

  // LINK BUTTON DEFAULT SIZE
  &--link {
    display: inline-flex;
  }

  // BUTTON SIZES
  &--md {
    @include padding($vertical: 'xs', $horizontal: 'sm');
  }

  &--sm {
    @include padding($vertical: 'xxs', $horizontal: 'lg');
  }

  // BUTTON MEDIA QUERY & EXCLUSIONS
  @include mq($size: 'xs', $type: 'max', $pixels: $largest-mobile-screen) {
    &:is(button):not(.btn-searchbar-open) {
      @include width($value: map-get($widths, '100'));
    }
  }
}
