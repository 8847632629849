header {
  position: relative;
  z-index: 20;
  width: 100%;
  justify-content: space-between;

  background-color: var(--env-color);

  @media screen and (max-width: $largest-tablet-screen) {
    width: 100%;
    // max-width: $navDesktopWidth;
  }

  .q.container.layout {
    position: fixed;
    display: flex;
    z-index: 2000;
    width: $navDesktopWidth;

    height: 100vh;
    flex-flow: column;
    justify-content: space-between;

    @media only screen and (max-width: $largest-tablet-screen) {
      display: none;
    }

    .upper-section {
      flex-grow: 1;
      background: var(--env-color);

      .logo-container {
        display: flex;

        width: 100%;
        height: 70px;
        padding: map-get($sizes, 'lg');
        padding-top: 25px;
        padding-right: 30px;
        padding-left: 30px;

        img {
          width: 100%;
        }
      }
    }

    .ui.pointing.secondary.menu {
      flex-flow: column;
      margin-left: 0px;

      .active.item {
        border: none;
      }
      .item {
        width: 100%;
        align-self: flex-start;
        padding: 0px;
        margin: 0px;
        a {
          width: 100%;
        }
      }
    }
  }

  .q.container.layout,
  .mobile-navigation {
    .bottom-section {
      @extend %text-size-xss;
      @extend %text-color-gray800;

      @include padding($top: 'none', $right: 'lg', $bottom: 'lg', $left: 'lg');
      background: var(--env-color);

      @media screen and (max-width: $largest-tablet-screen) {
        padding-bottom: 10vh;
      }

      .anontools {
        a {
          @extend %text-size-xss;
          @extend %text-weight-medium;
          text-decoration: none;

          &:hover {
            color: map-get($colors, 'primary');
          }
        }
      }

      .language-selector {
        padding-bottom: map-get($sizes, 'xxs');
        border-bottom: 1px solid black;
        margin-right: 0;
        margin-bottom: map-get($sizes, 'md');
        text-transform: uppercase;

        a,
        .current-language {
          margin-right: map-get($sizes, 'xs');
          text-decoration: none;

          &::after {
            display: none;
          }

          &.selected {
            color: $black;
          }
        }
      }
    }

    .bottom-links-container {
      display: flex;
      flex-flow: column;
      a {
        font-weight: $font-weight-500;
        text-decoration: none;
      }
    }

    .social-media-container {
      margin-top: 37px;
      color: $black;

      .logo-link {
        display: inline-block;
      }

      .icon {
        margin-right: 7px;
      }
    }
  }
}

.hero-slot {
  width: 100%;
}

.start-slider-slot {
  width: 100%;
}

.Toastify {
  height: 0;
  grid-area: toastify;
}

.submenu-wrapper,
.subsubmenu-wrapper {
  position: absolute;
  top: 0;
  width: calc($navDesktopWidth + 40px);
  height: 100vh;

  margin-top: 0;
  background: $white;
  box-shadow: 3px 0 10px 0 rgba(0, 0, 0, 0.05);

  @media screen and (max-width: $largest-tablet-screen) {
    display: none;
    clip: rect(0 0 0 0);
  }

  ul {
    padding-left: 0;
    list-style: none;
  }

  h2 {
    padding-top: map-get($sizes, 'xl');
    padding-left: map-get($sizes, 'lg');
    border-bottom: none;
    margin-bottom: map-get($sizes, 'xl');
    font-size: 11px;
    letter-spacing: 1px;

    text-transform: uppercase;
    @extend %text-color-gray500;
  }

  &.hide {
    display: none;
  }
}

.submenu-wrapper {
  right: unset;
  z-index: 1900;
  left: $navDesktopWidth;
  margin-right: unset;
  margin-left: unset;
  background: $white;
  list-style: none;
}

.subsubmenu-wrapper {
  position: absolute;
  z-index: 1700;
  top: 0px;
  left: 720px;
  padding-left: 0px;
  padding-left: 0px;
}
