.panels-widget {
  .items-area {
    padding: 1em 0em;
    margin-right: 10px;
    margin-left: 10px;

    .panel-item {
      display: flex;
      width: 100%;
      justify-content: space-between;
      padding: 12px 9px;
      border: 1px solid #ddd;
      background: #f3f4f5;
    }

    [data-rbd-draggable-context-id] {
      margin-bottom: 0.3em;
    }

    .drag.handle.wrapper {
      min-height: auto;
    }
  }
}

.block-editor-accordion .ui.accordion .accordion {
  padding: 0;
  margin: 0;
}

.block-editor-accordion .accordion-content {
  padding: 0 1rem;
}

.block-editor-accordion .accordion-body.open > div {
  // If not set, the toolbar can't overflow the accordion body
  overflow: initial;
}

.block-editor-accordion .block-toolbar {
  position: absolute;
  z-index: 3;
  right: -9px;
  display: flex;
  justify-content: end;
  border: 1px solid rgba(120, 192, 215, 0.75);
  border-bottom-color: #fff;
  margin-top: -50px;
  background-color: #fff;
  border-top-left-radius: 1rem;
  border-top-right-radius: 1rem;
}

.block-editor-accordion .block-toolbar button {
  padding: 0.5rem;
  border: none;
  background: none;
  svg.circled {
    padding: 0;
    border: 1px solid currentColor;
  }
}

.block-editor-accordion
  [class^='block-editor-']
  .block
  .block:not(.inner):before {
  z-index: auto;
}
