// #page-document .blocks-group-wrapper > h1.documentFirstHeading {
//   @extend %h1;
//   margin-bottom: calculateRem(13px);
//   margin-left: calculateRem(20px);
// }

.header {
  &.pulled {
    h2 {
      border: none;
    }
  }
}

.ui.button.full-size-sidenav-btn {
  padding: 7px 0 12px 6px;
}
