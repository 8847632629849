.calendarSvg {
  vertical-align: sub;
  margin-right: map-get($sizes, 'xxs');
}
.ics-download {
  display: inline-flex;
  align-items: center;
  @include margin($top: 'none', $right: 'none', $bottom: 'none', $left: 'xs');
  @extend %text-size-sm;
  @extend %text-weight-semibold;
}
