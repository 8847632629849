.select-container {
  display: flex;
  align-items: end;
  margin-bottom: 32px;
  gap: 32px;
}

.search-container {
  position: relative;
  margin-bottom: 32px;

  .clear-icon {
    position: absolute;
    top: 12px;
    right: 10px;
    border: none;
    cursor: pointer;
  }
}

.clear-all {
  border: none;
  margin-bottom: 45px;
  background: none;
  text-decoration: underline;
}

.reset-toggle-container {
  display: flex;
  align-items: baseline;
  justify-content: space-between;

  .sort-container {
    button {
      display: flex;
      align-items: baseline;
      border: none;
      background: transparent;
      color: $gray-700;
      cursor: pointer;
      text-decoration: underline;
    }
    .icon {
      margin-left: 15px;

      &.reverse {
        transform: rotate(180deg);
      }
    }
  }
}
.more-result-container {
  display: flex;
  justify-content: end;

  .more-container {
    justify-content: flex-end;
    button {
      border: none;
      background: transparent;
      color: $gray-700;
      cursor: pointer;
      text-decoration: underline;
    }
    span {
      border: none;
      background: transparent;
      color: $gray-700;
    }
  }
}

.solr-search-news-view-template,
.reset-toggle-container {
  .toggle-button {
    button {
      border: none;
      background: white;
      cursor: pointer;
    }
    button:first-of-type {
      margin-right: 5px;
    }
  }
}

.solr-search-news-view-template {
  .toggle-button.news-view {
    display: flex;
    justify-content: flex-end;
    margin-bottom: 20px;
  }
}

.resultview-container {
  overflow: hidden;
}

@include mq($size: xs, $type: max) {
  .toggle-button .view-icon.grid {
    display: none;
  }
}

.ui.table.table-result-view-container {
  border: none;
  th {
    background: #fff;
  }
  .sortable-header {
    cursor: pointer;
    .icon {
      position: relative;
      top: 2px;
      margin-left: 10px;

      &.asc {
        transform: rotate(-90deg);
      }

      &.reverse {
        transform: rotate(90deg);

        &.faded {
          fill: #deded9 !important;
        }
      }
    }
  }
  .arrow-right {
    stroke-width: 2px;
  }

  .clickable-table-row {
    cursor: pointer;
  }
}
