@import '../_variables.scss';

#page-add
  .block-editor-gridBlock
  .block.gridBlock
  .block.image
  figcaption
  .description,
#page-edit
  .block-editor-gridBlock
  .block.gridBlock
  .block.image
  figcaption
  .description,
.block.gridBlock .block.image .description {
  display: none;
}
.block.gridBlock .grid-items .ui.grid {
  flex-wrap: nowrap;
}
.block.gridBlock .ui.message {
  background-color: $white;
}
