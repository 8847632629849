.navigation {
  height: auto;
  margin-top: map-get($sizes, 'xl');
}

.mobile-navigation {
  display: none;

  @media only screen and (max-width: $largest-tablet-screen) {
    display: flex;
    position: relative;
    width: 100%;

    height: 70px;
    align-items: center;
    justify-content: space-between;
    background: var(---env-color);
  }

  .logo-container {
    padding-top: map-get($sizes, 'xxs');
    padding-left: map-get($sizes, 'md');
  }
}

.mobile-nav {
  z-index: 1;
  display: flex;
  flex-direction: row;
  align-items: center;
  justify-content: space-between;
  padding-right: map-get($sizes, 'md');

  .hamburger {
    position: absolute;
    display: flex;
    align-items: center;
    justify-content: center;
    right: 50px;
    border: 2px solid transparent;
    border-radius: 0;
    color: $black;
    cursor: pointer;
    background: transparent;
    width: 70px;
    height: 100%;

    &::before {
      position: relative;
      top: 2px;
      width: 20px;
      height: 20px;
      margin-right: 10px;
      content: '';

      transform-origin: left;
      transition: transform 0.5s ease-in;

      @include backgroundIcon('hamburger');
    }

    &.is-active {
      &::before {
        top: 0;
        left: 0;
        transition: transform 0.5s ease-in;
        @include backgroundIcon('xcross');
      }
    }
  }

  .mobile-navigation-search {
    position: absolute;
    display: flex;
    align-items: center;
    justify-content: center;
    right: 0;
    border: 2px solid transparent;
    border-radius: 0;
    color: $black;
    cursor: pointer;
    background: transparent;
    width: 70px;
    height: 100%;

    .visually-hidden {
      position: absolute;
      width: 1px;
      height: 1px;
      padding: 0;
      margin: -1px;
      overflow: hidden;
      clip: rect(0, 0, 0, 0);
      white-space: nowrap;
      border: 0;
    }

    &::before {
      position: relative;
      left: 0;
      padding-right: map-get($sizes, 'lg');
      content: '';
      @include backgroundIcon('magnifying-glass');
    }
  }

  .menu-drawer-mobile-navigation {
    position: fixed;
    z-index: 0;
    top: 70px;
    left: 0;
    display: flex;
    width: 100vw;
    height: calc(100vh - 70px);
    flex-direction: column;
    padding-bottom: 0px;
    text-align: left;
    overflow-x: hidden;
    overflow-y: auto;
    scrollbar-width: none;
    -webkit-overflow-scrolling: touch;
    -ms-overflow-style: none;

    &::-webkit-scrollbar {
      display: none;
    }

    .puller-pusher-container {
      position: relative;
      display: flex;
      width: 300vw;
      height: 100%;
      transition: transform 0.3s ease-in-out;
      min-height: min-content;

      .nav {
        display: flex;
        width: 100vw;
        height: 100%;
        flex-flow: column;
        padding-top: map-get($sizes, 'lg');
        box-shadow: inset 0px 2px 10px 0 rgba(0, 0, 0, 0.15);
        scrollbar-width: none;
        overflow-y: auto;
        -webkit-overflow-scrolling: touch;

        &::-webkit-scrollbar {
          display: none;
        }

        .back-button {
          padding-left: 20px;
          border: none;
          background: none;
          cursor: pointer;
          text-align: left;
        }

        ul {
          padding-left: 0px;
          margin-top: 0px;
          list-style: none;

          > li:last-child {
            display: inline-block;
            width: 100%;
            height: 100px;
          }
        }
      }

      .level1 {
        justify-content: space-between;
        background-color: $yellow;
      }

      .level2 {
        background-color: map-get($colors, 'white');
      }

      .level3 {
        background-color: map-get($colors, 'white');
      }
    }

    @media only screen and (max-width: $largest-tablet-screen) {
      &.slide-out-up {
        display: none;
        clip: rect(0 0 0 0);
      }
    }
  }
}
