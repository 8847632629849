.web25__infobox {
  position: relative;

  display: grid;
  padding: map-get($sizes, 'md');
  margin: map-get($sizes, 'xl') 0;

  @media screen and (min-width: $largest-tablet-screen) {
    position: relative;
    grid-template-rows: 1fr;
    justify-items: start;
    padding: 0 0 map-get($sizes, 'lg') 0;
  }

  @media screen and (max-width: $largest-tablet-screen) {
    left: -(map-get($sizes, 'xl'));
    width: calc(100% + 100px);

    background: map-get($colors, 'gray200');
    padding: map-get($sizes, 'xl');
  }

  @media screen and (max-width: $largest-mobile-screen) {
    left: -15px;
    width: calc(100% + 30px);
    padding: map-get($sizes, 'md');
    padding-bottom: map-get($sizes, 'xl');
  }

  .fullbleed-infobox-wrapper {
    justify-content: start;

    @media screen and (min-width: $largest-tablet-screen) {
      position: absolute;
      left: -(map-get($sizes, 'xl'));
      top: -40px;
      bottom: 0;

      display: flex;
      justify-content: center;
      align-items: normal;

      padding-top: 0;
      background: map-get($colors, 'gray200');
      width: 100vmax;
    }
  }

  .infobox-wrapper {
    position: relative;

    display: flex;
    flex-direction: row;
    justify-content: flex-start;
    align-items: flex-start;

    margin-left: 0 !important;
    gap: map-get($sizes, 'lg');

    @media screen and (max-width: $largest-tablet-screen) {
      flex-direction: column;
    }

    .fullbleed-teaser-box {
      flex: 1;

      h3 {
        @extend %h2;

        &::after {
          content: unset;
        }
      }

      // LINK
      a.btn {
        margin-top: map-get($sizes, 'xl');
        width: fit-content;
      }
    }

    .infobox-image {
      flex: 1;
      background-color: map-get($colors, 'gray200');

      img {
        width: 100%;
        height: auto;
      }

      @media screen and (max-width: $largest-tablet-screen) {
        max-width: 100%;
        height: auto;
      }
    }

    @media screen and (max-width: $largest-tablet-screen) {
      width: 100%;
    }
  }
}

// CMS EDIT OVERRIDE
#page-edit {
  .web25__infobox {
    position: relative;
    background-color: map-get($colors, 'gray200');
    padding: map-get($sizes, 'md');

    .infobox-image {
      position: relative;
    }

    .fullbleed-infobox-wrapper {
      position: relative;
      width: 100%;
      inset: 0;
      padding: 0;
    }
  }

  .styled-teaser,
  .block.teaser {
    .blocks-group-wrapper {
      padding-top: 0;
      padding-bottom: 0;
    }
  }

  .block.teaser.hovered {
    &:hover {
      img {
        animation-name: unset !important;
        opacity: 1;
      }
    }
  }
}
