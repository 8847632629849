@import '../variables';

.react-aria-TextField {
  display: flex;
  flex-direction: row;
  flex-wrap: wrap;
  justify-content: space-between;

  input,
  textarea {
    @extend .font-inter;
    @extend %bg-gray100;
    @extend %text-weight-regular;
    @extend %lh-lg;
    @extend %p-sm;
    @extend %mt-xxs;
    width: 100%;

    border: 2px solid $gray-400;

    &[data-focused] {
      @extend %text-color-gray800;
      @extend %bg-white;
      border-color: $black;
      outline: unset;
    }

    &[data-invalid] {
      border-color: map-get($colors, 'error');
    }

    &[data-disabled] {
      background: $gray-200;
    }

    // TBD - MISSING PREFIXES FOR ALL BROWSERS
    &::-webkit-input-placeholder,
    &::placeholder {
      @extend %text-color-gray600;
    }
  }

  > [slot='description'] {
    margin-top: map-get($sizes, 'xxs');
    &:nth-last-of-type(1) {
      @extend %text-size-xxs;
      @extend %lh-md;
      @extend %text-color-gray700;
    }
  }

  label {
    max-width: 50%;
  }

  .react-aria-FieldError {
    @extend %text-color-error;
    @extend %text-size-xxs;
    max-width: 50%;
    align-self: flex-end;
    text-align: end;
  }
}
