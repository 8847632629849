.folder-contents .ui.table .icon-align-name {
  text-decoration: none;
}

.contenttype-image figcaption .description p {
  display: block;
  @extend %text-color-gray700;
  @extend %text-size-sm;
  @extend %text-weight-regular;
  @extend %lh-xl;
  @extend %mb-xl;
}

.headline {
  @extend %h2;
}

.ui.drag.block.inner.teaser,
.block.teaser.infobox,
.styled-teaser.left.align.styled,
.block.teaser.fullBleedTeaser {
  &::after {
    content: unset;
  }
}
