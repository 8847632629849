@import '../variables';

.react-select-container {
  display: flex;
  width: 100%;
  flex-direction: column;

  .react-select-label-wrapper {
    display: flex;
    flex-direction: row;
    justify-content: space-between;

    label {
      max-width: 50%;
      align-self: flex-end;
    }

    .react-select-error {
      @extend %text-color-error;
      @extend %text-size-xxs;
      max-width: 50%;
      align-self: flex-end;
      text-align: end;
    }
  }

  .react-select-component {
    .react-select__control {
      @extend %mt-xxs;
      min-height: 3.75rem;
      box-sizing: border-box;
      border: 2px solid $gray-400;
      border-style: solid;
      border-radius: 0;
      background-color: $gray-100;
      transition: border-color 0.1s ease;

      &.react-select__control--is-focused {
        border: 2px solid $black;
        box-shadow: none;
        outline: none;
      }

      &.react-select__control--menu-is-open {
        &.react-select__control--is-focused {
          border-color: $black;
          border-bottom: 0;
          box-shadow: none;
          outline: none;
          transition: border-color 0.1s ease;
        }
      }

      .react-select__indicators svg {
        width: calculateRem(22px);
        height: calculateRem(16px);
        color: $gray-800;
      }

      .react-select__indicator-separator {
        display: none;
      }

      .react-select__multi-value {
        padding: 0.2rem 0.3rem 0.2rem 0.3rem;
        margin: 0.2rem 0.2rem 0.2rem 0.5rem;
        background-color: $yellow-3;
        color: $gray-700;

        .react-select__multi-value__remove {
          &:hover {
            background-color: $yellow-3;
            color: $gray-900;
          }
        }
      }
    }

    &.has-error .react-select__control {
      border-color: $red;
    }

    .react-select__menu {
      width: 100%;
      box-sizing: border-box;
      border: 2px solid $black;
      border-radius: 0;
      border-top: 0;
      margin-top: 0;
      margin-bottom: 0;
      background-color: $gray-100;
      box-shadow: none;
      outline: none;
      transition: border-color 0.2s ease;

      .react-select__menu-list {
        padding: 0;
        background-color: $white;
      }

      .react-select__option {
        padding: 1rem;
        background-color: $white;
        color: $gray-900;
        cursor: pointer;
        font-size: $font-size-16;
        font-size: 1rem;
        line-height: 1.5;
        text-align: left;

        &.react-select__option--is-focused {
          background-color: $gray-100;
        }

        &.react-select__option--is-selected {
          background-color: $yellow-3;
        }

        &.react-select__option--is-disabled {
          color: $gray-500;
          cursor: not-allowed;
        }
      }
    }
  }

  > [slot='description'] {
    margin-top: map-get($sizes, 'xxs');
    &:nth-last-of-type(1) {
      @extend %text-size-xxs;
      @extend %lh-md;
      @extend %text-color-gray700;
    }
  }

  .react-select-error {
    @extend %text-color-gray700;
    @extend %text-size-xs;
    padding: 0.5rem 0 0 0;
  }
}
