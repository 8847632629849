$smallSpacing: map-get($sizes, 'xs');
$middleSpacing: map-get($sizes, 'sm');
$largeSpacing: map-get($sizes, 'md');

// TOC
ul.ui.list:last-child,
ol.ui.list:last-child,
.ui.list:last-child {
  padding-bottom: map-get($sizes, 'lg');
}

// TEASER
.block {
  &.teaser {
    &::after {
      content: unset;
    }
  }
}

.heading-wrapper {
  position: relative;
}

#page-document {
  .blocks-group-wrapper {
    .block {
      &.accordionBlock,
      &.introduction,
      &.button,
      &.listing,
      &.teaser,
      &.video,
      &.maps,
      &.separator,
      &.highlight,
      &.search,
      &.accordion,
      &.toc,
      &.eventMetadata,
      &.gridBlock {
        @media screen and (min-width: 0) and (max-width: $largest-mobile-screen) {
          // background-color: skyblue;
          margin: $smallSpacing 0 $smallSpacing 0 !important;
          padding: $smallSpacing 0 $smallSpacing 0 !important;
        }

        @media screen and (min-width: $largest-mobile-screen) {
          // background-color: palegreen;
          margin: $middleSpacing 0 $middleSpacing 0 !important;
          padding: $middleSpacing 0 $middleSpacing 0 !important;
        }

        @media screen and (min-width: $smallest-desktop-screen) {
          // background-color: blueviolet;
          margin: $largeSpacing 0 $largeSpacing 0 !important;
          padding: $largeSpacing 0 $largeSpacing 0 !important;
        }
      }
    }
  }

  .container,
  .block-container,
  .column.grid > .column,
  .button.container,
  .block.__button,
  .highlight-description {
    padding: 0;
    margin: 0;
  }
}

#page-document .blocks-group-wrapper:last-child {
  margin-bottom: $largeSpacing !important;
}

.block.separator .line::after {
  border-color: map-get($colors, 'gray500');
}
