$DEV_LOCAL: map-get($colors, 'gray400');
$ACCEPTANCE: $green;
$DEV: $purple;
$TEST: $lightblue;

:root {
  --layout-container-width: 1280px;
  --default-container-width: unset;
  --narrow-container-width: unset;
  --env-color: #{$yellow};
}

body.env-DEV_LOCAL {
  --env-color: #{$DEV_LOCAL};
}

body.env-ACCEPTANCE {
  --env-color: #{$ACCEPTANCE};
}

body.env-DEV {
  --env-color: #{$DEV};
}

body.env-TEST {
  --env-color: #{$TEST};
}

html {
  box-sizing: border-box;
  background-color: $white;
  font-family:
    'Inter',
    -apple-system,
    BlinkMacSystemFont,
    'Segoe UI',
    'Roboto',
    'Oxygen',
    'Ubuntu',
    'Cantarell',
    'Fira Sans',
    'Droid Sans',
    'Helvetica Neue',
    sans-serif;
  font-size: $defaultFontSize;
}

body {
  width: 100%;
  color: $gray-800;
  overflow-x: visible;
}

table {
  width: 100%;
}

// GENERAL BOX SIZING IN INHERITS

.d-block {
  display: block;
}

.content-slot {
  .text-center {
    text-align: center;
  }

  .text-left {
    text-align: left;
  }

  .text-end {
    text-align: end;
  }

  .text-justify {
    text-align: justify;
  }

  @for $i from 1 through 10 {
    .indent-#{$i} {
      margin-left: 1em * $i !important;
    }
  }
}

//Customized because we are not able to remove the original link editor component
.slate-inline-toolbar.add-link.slate-toolbar.slate-menu {
  display: none;
}

.slate-inline-toolbar #align-menu {
  width: 32px;
  height: 32px;
}

#blockform-fieldset-template-options .date-time-widget-wrapper {
  .SingleDatePickerInput {
    .DateInput_input {
      height: 60px;
    }
  }
}

.visually-hidden:not(:focus):not(:active) {
  position: absolute;
  overflow: hidden;
  width: 1px;
  height: 1px;
  clip-path: inset(50%);
  white-space: nowrap;
}

body.prevent-scroll {
  overflow: hidden;
}