%peerReviewedIcon {
  &::before {
    @include backgroundIcon('checkmark');
  }
}

@mixin listBorder($position) {
  border-#{$position}: 1px solid $gray-400;
}

$border-position: top; // Local variable for border position

.web25_irf-publication {
  position: relative;
  overflow: hidden;

  .teaser-listing.linklist.has-anim {
    margin-top: map-get($sizes, 'none');
    margin-bottom: map-get($sizes, 'lg');
    @include listBorder(top);

    .teaser-link {
      &:hover {
        color: inherit;
      }
    }

    li.no-peer-reviewed-message,
    li.data-processed {
      display: inline-block;
      width: 100%;
      position: relative;
      @include listBorder(bottom);

      p {
        font-size: $font-size-15;
        @extend %peerReviewedIcon;
        @include padding(
          $top: 'md',
          $right: 'none',
          $bottom: 'md',
          $left: 'lg'
        );
      }
    }

    li.teaser {
      padding-bottom: map-get($sizes, 'xs');
    }

    .teaser-wrapper {
      display: flex;
      flex-direction: column;
      flex-wrap: wrap;
      padding-bottom: map-get($sizes, 'lg');

      &:first-child {
        span.peer-reviewed {
          padding-top: map-get($sizes, 'xs');
        }
      }

      .csl-entry,
      p {
        font-size: $font-size-15;
        color: map-get($colors, 'gray800');
      }

      h3 {
        display: block;
        width: 100%;
        padding: map-get($sizes, 'none');
        @extend %text-color-primary;
        @extend %text-size-md;
        line-height: $line-height-4;
        margin-bottom: map-get($sizes, 'none');

        @media screen and (max-width: $largest-mobile-screen) {
          padding-bottom: map-get($sizes, 'md');
        }
      }

      span.peer-reviewed {
        display: inline-block;
        position: relative;
        padding-left: map-get($sizes, 'lg');
        font-size: $font-size-15;
        line-height: 2px;
        color: $gray-700;

        &::before {
          position: absolute;
          left: 0;
          top: 0;
          @include backgroundIcon('checkmark');
        }

        @media screen and (max-width: $largest-mobile-screen) {
          line-height: 0;
          margin-bottom: map-get($sizes, 'sm');
        }
      }

      &::after {
        position: absolute;
        inset: auto auto 0 auto;
        @include backgroundIcon('arrow');
        @include animate(slide-out-arrow);
      }

      &:hover {
        &::after {
          @include animate(slide-in-arrow);
        }
      }

      @media screen and (min-width: $largest-mobile-screen) {
        padding-bottom: map-get($sizes, 'xs');

        &::after {
          content: unset !important;
        }
      }

      @media screen and (max-width: $largest-mobile-screen) {
        margin-bottom: map-get($sizes, 'xs');
        margin-top: map-get($sizes, 'xs');
      }

      span.widg_linklist__dateline {
        display: inline-block;
        padding-top: map-get($sizes, 'sm');

        @extend %text-color-gray700;
        @extend %text-weight-medium;
        @extend %text-size-xss;
      }
    }
  }

  // SWITCH - PEER REVIEWED
  .irf-switch {
    display: flex;
    justify-content: space-between;
    align-items: center;
    // width: 100%;
    padding-top: map-get($sizes, 'md');
    padding-bottom: map-get($sizes, 'md');

    label.react-aria-Switch {
      cursor: pointer;
      @extend %text-size-xs;
      @extend %text-color-primary;
    }
  }
}

// NAVIGATION - GRADIENT PREV/NEXT SCROLL
.irf-navigation {
  position: relative;
  overflow: hidden;
  display: flex;

  margin-top: map-get($sizes, 'lg');

  ul.irf-tabs {
    display: flex;
    flex-direction: row;
    flex-wrap: nowrap;
    overflow-x: hidden;
    // transition: transform 500ms cubic-bezier(0.1, 0.7, 1, 0.1);
    list-style-type: none;
    z-index: 0;
    margin: 0;
    scrollbar-width: none;
    padding: 3px 0 0 0;

    /* Firefox 64 */
    overflow-x: scroll;
    -ms-overflow-style: none;

    /* IE 11 */
    &::-webkit-scrollbar {
      display: none;
      width: 0;
      height: 10px;
      background: transparent;
    }

    &::after {
      position: absolute;
      content: '';
      right: 0;
      top: 0;
      width: 50px;
      height: 50px;
      background: linear-gradient(
        90deg,
        rgba(255, 255, 255, 0) 0%,
        rgba(255, 255, 255, 1) 100%
      );
    }

    li {
      display: inline-flex;
      flex: 1 0 0;
      margin-right: calculateRem(40px);

      &:first-child {
        padding-left: 0;
      }

      .tab-button {
        cursor: pointer;

        background-color: transparent;
        text-align: left;
        padding-bottom: map-get($sizes, 'xs');
        border: 0;

        font-size: $font-size-18;
        font-weight: $font-weight-700;
        text-decoration: none;
        color: map-get($colors, 'gray700');
        transition: width 0.25s ease;
        white-space: nowrap;

        &:after {
          display: flex;
          content: '';
          margin-top: map-get($sizes, 'xs');
          height: map-get($sizes, 'xxs');
          width: 0;
          background: $yellow;
          transition: width 0.25s ease;
        }

        &.active {
          color: $black;

          &::after {
            width: 100%;
            transition: width 0.5s ease;
          }
        }

        &:hover {
          color: $black;
        }
      }
      &:last-of-type {
        .tab-button {
          margin-right: 50px;
        }
      }
    }

    &.gradient_next:before {
      position: absolute;
      left: 0;
      top: 0;
      width: 100px;
      height: 50px;
      z-index: 1;

      content: '';
      background: linear-gradient(
        90deg,
        rgba(255, 255, 255, 1) 0%,
        rgba(255, 255, 255, 0) 100%
      );
    }

    &.gradient_prev:after {
      position: absolute;
      right: 0;
      top: 0;
      width: 100px;
      height: 50px;
      z-index: 2;
      content: '';
      background: linear-gradient(
        90deg,
        rgba(255, 255, 255, 0) 0,
        rgb(255, 255, 255) 30%
      );
    }
  }

  ul.scroll-navigation {
    display: flex;
    flex-direction: row;
    justify-content: flex-start;
    align-items: flex-start;
    gap: 5px;
    list-style-type: none;
    margin: 0;
    background: transparent;
    z-index: 0;
    padding: 0;

    li {
      background-color: transparent;
      .scroll-button {
        display: flex;
        justify-content: center;
        align-items: center;
        padding: unset;
        width: map-get($sizes, 'lg');
      }

      .scroll-left {
        width: map-get($sizes, 'lg');
        height: map-get($sizes, 'lg');
      }

      .scroll-right {
        width: map-get($sizes, 'lg');
        height: map-get($sizes, 'lg');
      }
    }
  }

  @media screen and (max-width: $largest-tablet-screen) {
    :not(.volto-storybook-container) {
      ul.scroll-navigation {
        display: none;
      }
    }
  }
}

.loading-indicator {
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: center;
}

.dot-loader {
  display: flex;
  justify-content: center;
  align-items: center;
}

.dot-loader div {
  width: map-get($sizes, 'xxs');
  height: map-get($sizes, 'xxs');
  margin: 3px;
  background-color: map-get($colors, 'primary');
  border-radius: 50%;
  animation: dot-bounce 1.4s infinite ease-in-out both;
}

.dot-loader div:nth-child(1) {
  animation-delay: -0.32s;
}

.dot-loader div:nth-child(2) {
  animation-delay: -0.16s;
}

@keyframes dot-bounce {
  0%,
  80%,
  100% {
    transform: scale(0);
  }
  40% {
    transform: scale(1);
  }
}

@keyframes spin {
  0% {
    transform: rotate(0deg);
  }
  100% {
    transform: rotate(360deg);
  }
}

.processing-icon {
  display: inline-block;
  width: 20px;
  height: 20px;
  border-radius: 50%;
  border: 3px solid $black;
  border-top: 3px solid $yellow;
  animation: spin 1s linear infinite;
  text-indent: -10000px;
}

.checkmark-icon {
  width: 50px;
  height: 50px;
  border-radius: 50%;
  display: flex;
  align-items: center;
  justify-content: center;
  color: $green;
}

.checkmark-icon::before {
  content: '\2713'; /* Unicode character for a tick mark (✓) */
  font-size: 2em;
}

.checkmark-icon::before {
  content: '\2713'; /* Unicode character for a tick mark (✓) */
  font-size: 2em;
  // animation: spin 1s linear infinite;
}

.data-state,
.no-data-seleced,
.alert-warning {
  display: flex;
  justify-content: space-between;
  align-items: center;
  width: 100%;

  .alert-success,
  .alert-warning {
    width: 100%;

    p {
      @extend %text-size-xs;
      @extend %text-color-primary;
      margin: 0;
    }
  }
}

// CMS - EDIT and STORYBOOK TWEAKS
#page-edit,
#storybook-root {
  .web25_irf-publication {
    .blocks-group-wrapper {
      padding-top: 0;
    }
  }
}

#storybook-root {
  .no-data-seleced {
    display: none;
  }
}
