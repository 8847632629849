@import '../variables';

.group-block-view.show_on_desktop.show_on_mobile {
  display: block;
}
.group-block-view.hide_on_all_viewports {
  display: none;
}

.group-block-view.show_on_desktop {
  @media only screen and (max-width: $largest-tablet-screen) {
    display: none;
  }
}
.group-block-view.show_on_mobile {
  @media only screen and (min-width: $largest-tablet-screen) {
    display: none;
  }
}
