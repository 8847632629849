@import '../variables';

.react-aria-Calendar {
  width: fit-content;
  max-width: 100%;
  color: $black;
  font-size: 0.8rem;
  font-weight: 400;

  header {
    display: flex;
    align-items: center;
    margin: 0 4px 1.5rem 4px;
    background-color: $white;

    .react-aria-Heading {
      flex: 1;
      padding-bottom: 0;
      border-bottom: 0;
      margin: 0;
      font-size: 1.375rem;
      text-align: center;
    }
  }

  .react-aria-Button {
    width: 2rem;
    height: 2rem;
    padding: 0;
    border: 0;
    background-color: $gray-100;
  }

  .react-aria-CalendarCell {
    width: 2rem;
    border-radius: 0;
    margin: 1px;
    cursor: default;
    forced-color-adjust: none;
    line-height: 2rem;
    outline: none;
    text-align: center;

    &[data-outside-month] {
      display: none;
    }

    &[data-pressed] {
      background: $gray-200;
    }

    &[data-focus-visible] {
      outline: 2px solid $gray-800;
      outline-offset: 2px;
    }

    &[data-selected] {
      background: $yellow;
      color: $black;
      font-weight: 600;
    }
  }

  .react-aria-CalendarCell {
    &[data-disabled] {
      color: $gray-500;
    }
  }

  .react-aria-CalendarCell {
    &[data-unavailable] {
      color: $gray-600;
      text-decoration: line-through;
    }
  }

  .react-aria-CalendarCell {
    &[data-invalid] {
      background: #fcedf0;
      color: $red;
    }
  }

  [slot='errorMessage'] {
    color: $red;
    font-size: 12px;
  }

  .react-aria-CalendarGrid {
    td {
      padding: calculateRem(4px);
    }
  }
}
