@import '../_variables.scss';
@import '../globals/spacings';

// TEASER - DEFAULTS
.teaser {
  display: flex;
  box-sizing: border-box;
  flex-direction: column;
  @extend %p-sm;

  @extend %text-color-gray700;
  @extend %text-size-xsm;
  @extend %mb-xs;
  @extend %lh-xxl;

  &-image {
    position: relative;
    display: flex;
    overflow: hidden;

    width: 100%;
    height: auto;
    flex-direction: column;
    align-items: center;
    justify-content: center;
    // aspect-ratio: 16 / 9;

    img {
      @extend %imageSize;
      @extend .scale-out;

      &:hover {
        @extend .scale-in;
      }
    }

    &--no-img {
      flex: 3;

      .teaser-dates--only {
        @extend %pt-none;
      }
    }
  }

  &-dates {
    position: absolute;
    bottom: 0;
    left: 0;
    display: flex;
    flex-direction: column;
    justify-content: space-between;

    @include padding($top: 'xs', $right: 'lg', $bottom: 'none', $left: 'none');
    @extend %bg-white;

    @include mq($size: 'sm', $type: 'max') {
      @include padding(
        $top: 'xs',
        $right: 'sm',
        $bottom: 'none',
        $left: 'none'
      );
    }

    @include mq($size: 'xs', $type: 'max') {
      @include padding(
        $top: 'xs',
        $right: 'md',
        $bottom: 'none',
        $left: 'none'
      );
    }

    &--only {
      @extend .teaser-dates;
      position: unset;
      top: 0;
      justify-content: flex-start;
    }

    > span {
      &:first-child {
        @include padding(
          $top: 'none',
          $right: 'none',
          $bottom: 'xs',
          $left: 'none'
        );
      }

      &:last-child {
        @include padding(
          $top: 'none',
          $right: 'none',
          $bottom: 'none',
          $left: 'none'
        );
      }

      font-size: calculateRem($font-size-24);
      @extend %text-weight-semibold;
      @extend %text-color-gray700;
      @extend %lh-xxs;
    }

    &:not(:has(span)) {
      display: none;
    }
  }

  &-content {
    width: 100%;
    box-sizing: border-box;

    &--pointed-mark {
      font-size: calculateRem(13px);
      @extend %text-color-gray600;
      @extend %text-weight-medium;
      @extend %mt-none;
      @extend %mb-none;
      @extend .d-block;
      @include padding(
        $top: 'none',
        $right: 'none',
        $bottom: 'xxs',
        $left: 'none'
      );
    }

    h3,
    h3:last-child {
      display: flex;
      align-items: flex-start;
      justify-content: space-between;

      @extend %text-size-md;
      @extend %text-weight-bold;
      @extend %lh-lg;
      @extend %mt-xs;
      @extend %mb-none;
      @extend %text-color-primary;

      &::after {
        @include backgroundIcon($iconName: 'arrow');
        @include margin(
          $top: 'xs',
          $right: 'none',
          $bottom: 'none',
          $left: 'md'
        );

        min-width: map-get($sizes, 'lg');
        background-position: 0 0;
      }

      @include mq($size: 'xs', $type: 'min', $pixels: $largest-mobile-screen) {
        &::after {
          content: unset !important;
        }
      }
    }

    &--addition {
      @extend %text-size-xsm;
      @extend %text-color-primary;
      font-weight: $font-weight-300;
    }

    p {
      @extend %mb-xs;
      @extend %text-color-gray700;
    }

    &--profile {
      @extend .teaser-content;
      display: flex;
      box-sizing: border-box;
      flex-direction: row;
      justify-content: space-between;
      order: 4;
      @include padding($vertical: 'md', $horizontal: 'none');

      &--tel,
      &--email {
        display: flex;
        box-sizing: border-box;
        flex: 1;
        flex-direction: column;

        span {
          font-size: inherit;

          a {
            @include padding(
              $top: 'none',
              $right: 'none',
              $bottom: 'none',
              $left: 'none'
            );
          }
        }
      }
    }
  }

  &-link,
  .profile-link {
    display: flex;

    width: 100%;
    box-sizing: border-box;
    flex: 1;
    text-decoration: none;

    &:hover {
      text-decoration: none;
    }
  }

  &-wrapper {
    display: flex;
    flex: 1;
    flex-direction: column;
    align-items: flex-start;
    justify-content: flex-start;

    @extend %p-none;

    // BELOW LARGEST-MOBILE-SCREEN
    @include mq($size: 'xs', $type: 'max') {
      &::after {
        content: unset;
      }
    }

    a,
    a:hover {
      cursor: pointer;
      text-decoration: underline;
    }

    address {
      display: flex;
      flex: 7;
      flex-direction: column;
      font-style: normal;

      @include padding($top: 'xs', $right: 'lg', $bottom: 'sm', $left: 'none');

      a {
        text-decoration: underline;

        @include padding(
          $top: 'none',
          $right: 'none',
          $bottom: 'none',
          $left: 'xxs'
        );
      }

      span {
        display: flex;
        flex-direction: row;
        flex-wrap: nowrap;
        align-items: center;
        justify-content: flex-start;
        white-space: nowrap;
      }
    }
  }

  &:hover {
    @extend %bg-gray200;

    .teaser-dates {
      @extend %bg-gray200;
    }

    img {
      @extend .scale-in;
    }

    &::after {
      @include animate(slide-in-arrow);
    }
  }

  &::after {
    display: flex;
    @include backgroundIcon($iconName: 'arrow');
    @include animate(slide-out-arrow);
    margin: map-get($sizes, 'xs') 3px;

    @include mq($size: 'xs', $type: 'max') {
      content: unset !important;
    }
  }

  // OUTER WRAPPER - LISTINGS
  // 1/3 TEASER
  &-col-3 {
    @extend %listing;

    > .teaser {
      max-width: 33%;
      flex: 1 0 33%;
    }
  }

  // 1/4 TEASER
  &-col-4 {
    @extend %listing;

    > .teaser {
      max-width: 25%;
      flex: 1 0 25%;
    }
  }

  // UL WRAPPER - LISTING
  &-listing {
    list-style: none;

    @include padding($vertical: 'none', $horizontal: 'none');
    @include margin($vertical: 'none', $horizontal: 'none');

    .teaser {
      border-bottom: 1px solid map-get($colors, 'gray400');
      @include margin($vertical: 'none', $horizontal: 'none');

      &:last-child {
        border-bottom: unset;
      }

      &-wrapper {
        @include padding($top: 'xs', $right: 'xs', $bottom: 'lg', $left: 'xs');
      }
    }

    &.linklist {
      .teaser {
        @extend .teaser;
        @include padding($top: 'xs', $right: 'lg', $bottom: 'lg', $left: 'xs');

        &.new-tab {
          h3 {
            align-items: flex-end;

            &::after {
              @include backgroundIcon($iconName: 'externalLink');
              @include margin(
                $top: 'xs',
                $right: 'none',
                $bottom: 'none',
                $left: 'md'
              );

              min-width: map-get($sizes, 'lg');
              background-position: 0 0;
            }

            @include mq(
              $size: 'xs',
              $type: 'min',
              $pixels: $largest-mobile-screen
            ) {
              &::after {
                content: unset;
              }
            }
          }

          &::after {
            display: flex;
            @include backgroundIcon($iconName: 'externalLink');
            @include animate(scale-out);
            background-position: 0 0;
          }

          &:hover {
            &::after {
              @include animate(scale-in);
            }
          }

          @include mq(
            $size: 'xs',
            $type: 'max',
            $pixels: $largest-mobile-screen
          ) {
            &::after {
              content: unset;
            }
          }
        }

        &-image {
          @extend .teaser-image;
        }

        &-image--no-img {
          max-width: calculateRem(230px);
          flex: 3;
        }
      }
    }
  }

  // HOVER SLIDE ANIMATION
  &-listing,
  &-col-3,
  &-col-4 {
    &.has-anim {
      .teaser {
        &-wrapper {
          @extend .slide-out-hover;

          &:hover {
            @extend .slide-in-hover;
          }
        }
      }
    }
  }

  // MEDIEA QUERY - MOBILE DEVICES
  @include mq($size: xs, $type: max) {
    &-col-3,
    &-col-4,
    &-listing {
      display: flex;
      flex-direction: row;
      flex-wrap: wrap;

      .teaser {
        display: flex;
        min-width: 100%;
        flex-direction: row;
        align-items: center;
        justify-content: center;
        border-bottom: 1px solid map-get($colors, 'gray400');
        @include padding(
          $top: 'xs',
          $right: 'none',
          $bottom: 'lg',
          $left: 'none'
        );

        &-wrapper {
          animation-name: unset;
          transform: translateX(0);
          @include padding($vertical: 'none', $horizontal: 'xs');

          &:hover {
            animation-name: unset;
            transform: translateX(0);
          }
        }

        &-content {
          &:not(:has(.teaser-content--pointed-mark)) {
            h3 {
              width: 100%;
              @include margin($vertical: 'sm', $horizontal: 'none');
            }

            .profile-link {
              h3 {
                padding-bottom: 0;
                margin-bottom: 0;
              }
            }
          }

          h3 {
            @include margin(
              $top: 'xxs',
              $right: 'none',
              $bottom: 'sm',
              $left: 'none'
            );
          }

          &--profile {
            display: flex;
            flex-direction: column;
            justify-content: space-between;
            order: 4;
            padding-top: map-get($sizes, 'xs');

            &--tel,
            &--email {
              display: flex;
              box-sizing: border-box;
              flex: 1;
              flex-direction: column;

              span {
                font-size: inherit;

                a {
                  @include padding(
                    $top: 'none',
                    $right: 'none',
                    $bottom: 'none',
                    $left: 'xs'
                  );
                }
              }
            }
          }
        }

        &:not(.teaser-image) {
          .teaser-image--no-img {
            @include padding(
              $top: 'sm',
              $right: inherit,
              $bottom: 'none',
              $left: 'none'
            );
          }
        }

        &:has(.teaser-image) {
          .teaser-content--pointed-mark {
            @include margin(
              $top: 'xs',
              $right: 'none',
              $bottom: 'none',
              $left: 'none'
            );
          }
        }

        .teaser-content--pointed-mark {
          @include margin(
            $top: 'xxs',
            $right: 'none',
            $bottom: 'none',
            $left: 'none'
          );
        }
      }
    }

    &-col-3,
    &-col-4,
    &-listing {
      &.has-anim {
        .teaser {
          &-wrapper {
            animation-name: unset;
            transform: translateX(0);

            &:hover {
              animation-name: unset;
              transform: translateX(0);
            }
          }
        }
      }
    }
  }

  // MEDIA QUERY - FROM LARGEST MOBILE TO TABLET
  @include responsiveMinMax($largest-mobile-screen, $largest-tablet-screen) {
    &-col-3,
    &-col-4,
    &-listing {
      display: flex;
      flex-direction: row;
      flex-wrap: wrap;
      gap: 0;

      @include margin($vertical: 'none', $horizontal: 'none');

      .teaser {
        display: flex;
        min-width: 100%;
        flex-direction: row;
        align-items: center;
        justify-content: center;
        border-bottom: 1px solid map-get($colors, 'gray400');
        @include padding($top: 'xs', $right: 'xs', $bottom: 'lg', $left: 'xs');

        &-wrapper {
          max-width: 100%;
          flex-direction: row;
          gap: map-get($sizes, 'md');
          @include padding(
            $top: 'xs',
            $right: 'xs',
            $bottom: 'md',
            $left: 'xs'
          );
        }

        &-image {
          min-width: calculateRem(230px);
          flex: 3;
        }

        &-content {
          display: flex;
          flex: 7;
          flex-direction: column;
          align-items: flex-start;

          &--pointed-mark {
            order: 0;
            margin-top: map-get($sizes, 'none');
          }

          h3 {
            order: 1;
            margin: map-get($sizes, 'none');
          }

          &--addition {
            order: 2;
            margin-top: map-get($sizes, 'xs');
          }

          p {
            order: 3;
            padding-right: map-get($sizes, 'sm');
          }

          &--profile {
            display: flex;
            flex-direction: row;
            justify-content: space-between;
            order: 4;

            &--tel {
              display: flex;
              flex: 1;
              flex-direction: column;

              span {
                font-size: inherit;
                a {
                  @include padding(
                    $top: 'none',
                    $right: 'none',
                    $bottom: 'none',
                    $left: 'xs'
                  );
                }
              }
            }

            &--email {
              span {
                display: flex;
                flex-direction: column;
              }
            }
          }
        }
      }
    }

    &-col-3,
    &-col-4,
    &-listing {
      .teaser {
        @include padding(
          $top: 'xs',
          $right: 'xs',
          $bottom: 'md',
          $left: 'none'
        );
      }

      &.linklist {
        .teaser {
          @include padding(
            $top: 'xs',
            $right: 'md',
            $bottom: 'lg',
            $left: 'xs'
          );

          &-image {
            max-width: calculateRem(130px);
          }
        }
      }
    }
  }

  // LISTING - DESKTOP DEVICES
  @media (min-width: $largest-tablet-screen) {
    &-listing {
      display: flex;
      flex-direction: row;
      flex-wrap: wrap;
      gap: 0;

      .teaser {
        display: flex;
        min-width: 100%;
        flex-direction: row;
        align-items: center;
        justify-content: center;
        border-bottom: 1px solid map-get($colors, 'gray400');

        @include padding(
          $top: 'xs',
          $right: 'xs',
          $bottom: 'md',
          $left: 'none'
        );

        &-wrapper {
          max-width: 100%;
          flex-direction: row;
          gap: map-get($sizes, 'md');
        }

        &-image {
          min-width: calculateRem(230px);
          max-width: calculateRem(360px);
          flex: 4;

          &--no-img {
            min-width: calculateRem(230px);
            max-width: calculateRem(360px);
            flex: 4;
          }
        }

        &-content {
          display: flex;
          flex: 7;
          flex-direction: column;
          align-items: flex-start;

          &--addition {
            margin-top: map-get($sizes, 'xs');
          }

          h3 {
            margin-top: map-get($sizes, 'none');
            margin-bottom: map-get($sizes, 'none');
          }

          p {
            padding-right: map-get($sizes, 'sm');
          }

          &--profile {
            // background-color: salmon;
            display: flex;
            flex-direction: row;

            &--tel {
              span {
                padding-left: map-get($sizes, 'none');
              }
            }

            &--email {
              span {
                display: flex;
                flex-direction: column;

                a {
                  padding-left: map-get($sizes, 'none');
                }
              }
            }
          }
        }
      }

      &.linklist {
        .teaser {
          @include padding(
            $top: 'xs',
            $right: 'lg',
            $bottom: 'lg',
            $left: 'xs'
          );

          &-image {
            max-width: calculateRem(130px);
          }
        }
      }
    }

    &-col-3,
    &-col-4 {
      &.has-anim {
        .teaser {
          &-wrapper {
            animation-name: unset;
            transform: translateX(0);

            &:hover {
              animation-name: unset;
              transform: translateX(0);
            }
          }
        }
      }

      .teaser {
        &-image {
          &--no-img {
            flex: unset;
          }
        }

        &-content {
          &--profile {
            flex-direction: column;
            @include padding(
              $top: 'none',
              $right: 'none',
              $bottom: 'sm',
              $left: 'none'
            );

            &--tel,
            &--email {
              span:nth-last-child(2) {
                padding-top: map-get($sizes, 'sm');
              }
            }
          }

          &--pointed-mark {
            @include padding(
              $top: 'sm',
              $right: 'none',
              $bottom: 'none',
              $left: 'none'
            );
          }
        }

        &:not(:has(.teaser-image)) {
          &:not(:has(.teaser-content--pointed-mark)) {
            h3 {
              @include margin(
                $top: 'none',
                $right: 'none',
                $bottom: 'none',
                $left: 'none'
              );
            }
          }

          .teaser-content--pointed-mark {
            @include padding(
              $top: 'none',
              $right: 'none',
              $bottom: 'none',
              $left: 'none'
            );
          }
        }

        &:has(.teaser-image--no-img) {
          &:has(.teaser-dates--only) {
            h3 {
              @include margin(
                $top: 'xs',
                $right: 'none',
                $bottom: 'none',
                $left: 'none'
              );
            }
          }
        }
      }
    }
  }

  &.is-private {
    background-color: $lightpurple;
  }
}

%listing {
  display: flex;
  flex-wrap: wrap;
  gap: 0;
  list-style: none;

  @include padding($vertical: 'none', $horizontal: 'none');
  @include margin($vertical: 'none', $horizontal: 'none');
}

%imageSize {
  position: relative;
  width: 100%;
  object-fit: cover;
  object-position: center;
  aspect-ratio: 16 / 9;
}
