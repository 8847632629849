.contact-content-sidebar-wrapper {
  .related-contact-item {
    border: 1px solid $gray-400;
    border-bottom: none;

    &:last-child {
      border-bottom: 1px solid $gray-400;
    }

    &.open {
      border-bottom: 1px solid $gray-400;
      margin-bottom: map-get($sizes, 'lg');

      h4 {
        &::after {
          transform: rotate(0deg);
        }
      }
    }
  }

  .contact-item {
    display: block;
    overflow: hidden;
    margin-bottom: map-get($sizes, 'lg');
    background-color: map-get($colors, 'white');
    cursor: pointer;
    border: 1px solid $gray-400;

    @media (max-width: $largest-tablet-screen) {
      width: 100%;
    }

    &.open {
      .contact-content {
        grid-template-rows: 1fr;
      }
    }
  }

  button.contact-header {
    display: flex;
    width: 100%;
    cursor: pointer;
    background-color: white;
    padding: 10px 0;
    margin: 0;
    border: none;
    border-bottom: 1px solid $gray-400;

    &:hover {
      background-color: map-get($colors, 'gray200');
    }

    &[aria-expanded='false'] {
      border: none;
    }

    h4 {
      @extend %text-size-md;
      color: $gray-800;
      @include padding($top: 'xs', $right: 'md', $bottom: 'xs', $left: 'lg');
      display: flex;
      align-items: center;
      justify-content: space-between;
      width: 100%;
      transition: background-color 0.3s ease-in-out;
      margin: 0;
      border: none;
      text-align: left;

      &::after {
        display: flex;
        align-items: center;
        justify-content: center;

        content: '';
        right: 20px;
        min-width: 12px;
        @include backgroundIcon('xcross');
        background-repeat: no-repeat;
        background-size: auto;

        opacity: 0.7;
        transform: rotate(45deg);
        transition: transform 0.25s ease-in-out;
        @include margin(
          $top: 'none',
          $right: 'xs',
          $bottom: 'none',
          $left: 'sm'
        );
      }
    }
  }

  .contact-content {
    display: grid;
    grid-template-rows: 0fr;
    transition: 500ms grid-template-rows ease;
    background-color: map-get($colors, 'white');

    > div {
      overflow: hidden;
    }

    &.open {
      grid-template-rows: 1fr;
      transition: grid-template-rows 0.3s ease-out;
    }
  }

  .contact-content-sidebar {
    @include padding($top: 'md', $right: 'lg', $bottom: 'md', $left: 'lg');
    display: flex;
    flex-direction: row;
    align-items: flex-start;
    justify-content: flex-start;
    color: $gray-700;
    gap: map-get($sizes, 'lg');

    @extend %text-size-xsm;

    // Add red bottom border to contact-content-sidebar elements with 10px space on left and right
    position: relative;
    margin-bottom: map-get($sizes, 'md');
    padding-bottom: map-get($sizes, 'md');
    border-bottom: none;

    &:not(:last-child)::after {
      content: '';
      position: absolute;
      bottom: 0;
      left: map-get($sizes, 'lg');
      right: map-get($sizes, 'lg');
      height: 1px;
      background-color: $gray-400;
    }

    @media screen and (max-width: $largest-mobile-screen) {
      flex-direction: column;
    }

    @media screen and (min-width: calc($large-desktop-screen + 1px)) {
      flex-direction: column;
      gap: 10px;
    }

    .coordinates {
      display: flex;
      flex: 6;
      flex-direction: column;
      align-items: flex-start;
      justify-content: center;

      .role {
        @extend %text-size-xs;
        @extend %text-weight-regular;
        @extend %text-color-gray700;
        @extend %lh-lg;

        @include margin(
          $top: 'none',
          $right: 'none',
          $bottom: 'sm',
          $left: 'none'
        );
      }

      .heading {
        @extend %text-size-xs;
        @extend %text-weight-semibold;
        @extend %text-color-gray700;
        @extend %lh-lg;

        @include margin(
          $top: 'none',
          $right: 'none',
          $bottom: 'none',
          $left: 'none'
        );
      }

      .description {
        @extend %text-size-xsm;
        @extend %lh-md;
        white-space: pre-line;

        @include margin(
          $top: 'none',
          $right: 'none',
          $bottom: 'md',
          $left: 'none'
        );

        &.name {
          @extend %text-size-sm;
          @extend %text-color-primary;
          @extend %text-weight-medium;
          @extend %lh-md;
          text-decoration: underline;

          @include margin(
            $top: 'none',
            $right: 'none',
            $bottom: 'xs',
            $left: 'none'
          );
        }

        a {
          color: $black !important;
        }

        &:last-of-type:not(.description.name) {
          margin-bottom: 0;
        }
      }

      &_image {
        display: flex;
        width: 100%;
        flex: 4;
        align-items: center; // This centers vertically
        justify-content: center; // This centers horizontally

        a {
          display: flex;
          overflow: hidden;
          align-items: center; // Changed from flex-start to center
          justify-content: center;
          aspect-ratio: 16 / 9;
          width: 100%;

          img {
            aspect-ratio: 16 / 9;
            width: 100%;
            height: 100%; // Added to ensure full coverage
            @extend .scale-out;
            transition: scale 1s;
            object-fit: cover;
            object-position: center; // Added to ensure image is centered

            @media screen and (max-width: $largest-mobile-screen) {
              width: 100%;
              height: 100%; // Changed from auto to 100%
            }

            &:hover {
              @extend .scale-in;
            }
          }
        }
      }
    }
  }
}
