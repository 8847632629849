@import '../variables';

.react-aria-TimeField {
  display: flex;
  flex-direction: row;
  flex-wrap: wrap;
  justify-content: space-between;

  .react-aria-DateInput {
    padding: 4px 2.5rem 4px 8px;
    @extend %mt-xxs;
  }
}

.react-aria-TimeField {
  &[data-invalid] {
    .react-aria-DateInput {
      border-color: $red;
    }
  }

  .react-aria-Label {
    max-width: 50%;
    line-height: unset;
  }

  .react-aria-FieldError {
    max-width: 50%;
    color: $red;
    font-size: 12px;
  }

  > [slot='description'] {
    margin-top: map-get($sizes, 'xxs');
    &:nth-last-of-type(1) {
      @extend %text-size-xxs;
      @extend %lh-md;
      @extend %text-color-gray700;
    }
  }
}
