@import '../variables';

.sidebar-navigation-container {
  margin-bottom: map-get($sizes, 'lg');

  .stop-sign-anchor {
    display: flex;
    background: $black;

    color: map-get($colors, 'gray400');
    opacity: 0.8;
    text-decoration: none;
    @include padding($top: 'md', $right: 'md', $bottom: 'md', $left: 'md');
    @extend %text-size-xsm;
    @extend %text-weight-regular;

    &:hover {
      color: $white;
    }
  }

  h2 {
    position: relative;
    @include padding($top: 'md', $right: 'md', $bottom: 'md', $left: 'none');
    border: none;

    @extend %text-color-white;
    @extend %text-weight-light;

    &:after {
      position: absolute;
      bottom: 0;
      left: 0;
      display: block;
      width: map-get($sizes, 'xl');
      height: 1px;
      background-color: $white;
      content: ' ';
    }
  }
}

.sidebar-nav-right-wrapper {
  display: flex;
  flex-direction: column;
  position: relative;
  width: 100%;
  transform: translateY(-43px);

  @media screen and (max-width: $large-desktop-screen) {
    position: static;
    top: map-get($sizes, 'sm');
    margin-top: map-get($sizes, 'xl');
  }
}

.sub-navigation-container {
  display: flex;
  flex: 1;
  flex-direction: column;

  a {
    border-bottom: 2px solid $white;
  }
}

.stop-sign-container {
  position: absolute;
  width: 100%;
  line-height: 24px;
  transform: translateY(-100%);

  @media screen and (min-width: $smallest-desktop-screen) and (max-width: $large-desktop-screen) {
    display: none;
  }

  @media screen and (max-width: $smallest-desktop-screen) {
    display: none;
  }
}
